import { Container, ContentContainer, FlexColumn, FlexRow } from "../../common/components/WebLayoutComponents";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "@informed-innovations/components";
import { MobileBreakpoint } from "../../config/constants";
import { NPF_URL } from "../../config/pages";

const PageContainer = styled(ContentContainer)`
   margin-top: 35px;
   margin-bottom: 60px;
`;

const ProductContainer = styled(FlexRow)`
   .product-image {
      max-width: 55%;
      margin-right: 50px;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      .product-image {
         max-width: 100%;
         margin: 0 auto 35px;
      }
   }
`;

const ProductDetails = styled(FlexColumn)`
   .product-title {
      font-size: 51px;
   }

   .quantity {
      width: 64px;
      height: 38px;
      text-align: center;
   }

   .cart-button {
      max-width: 170px;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      .cart-button {
         max-width: 100%;
      }

      .product-title {
         margin-bottom: 20px;
         font-size: 30px;
      }

      .product-subtitle {
         margin-bottom: 30px;
      }
   }
`;

const ProductPrice = styled(FlexRow)`
   .price {
      font-size: 45px;
   }

   .in-stock {
      margin: auto 0 10px auto;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      margin-bottom: 20px;

      .price {
         font-size: 25px;
      }

      .in-stock {
         font-size: 16px;
      }
   }
`;

const NPFProduct = () => {
   const navigate = useNavigate();

   useEffect(() => {
      if (!sessionStorage.getItem("npf-item")) navigate(NPF_URL);
      else setCheckoutItem(JSON.parse(sessionStorage.getItem("npf-item")));
   }, [navigate]);

   const [checkoutItem, setCheckoutItem] = useState();

   const handleAddToCartClick = () => {
      navigate("/npf2024/cart");
   };

   return (
      <Container>
         <PageContainer>
            <ProductContainer>
               <img
                  src={checkoutItem?.sourceBig}
                  alt={checkoutItem?.alt}
                  className="product-image desktop-only-block"
               />
               <ProductDetails>
                  <span className="product-title">{checkoutItem?.title}</span>
                  <img
                     src={checkoutItem?.sourceBig}
                     alt={checkoutItem?.alt}
                     className="product-image mobile-only-block"
                  />
                  <ProductPrice>
                     <span className="price">FREE</span>
                     <span className="in-stock">IN STOCK</span>
                  </ProductPrice>
                  <hr style={{ border: "1px solid #333366", margin: "0 0 30px" }} className="desktop-only-block" />
                  <label className="desktop-only-block">Qty</label>
                  <input type="number" className="quantity medium-mb desktop-only-block" defaultValue={1} disabled />
                  <Button className="cart-button" onClick={handleAddToCartClick}>
                     Add to Cart
                  </Button>
               </ProductDetails>
            </ProductContainer>
         </PageContainer>
      </Container>
   );
};

export default NPFProduct;
