import mobileHero from "../../static/img/business-hero-mobile.jpg";
import businessBenefits from "../../static/img/business-benefits.jpg";
import codeScreen from "../../static/img/code-screen.jpg";
import lockerRetrieve from "../../static/img/locker-retrieve.jpg";
import labelApply from "../../static/img/applying-label-to-package-oversized.jpg";
import datastreamKeyboard from "../../static/img/datastream-over-keyboard.jpg";
import LogInternalLink from "../../common/components/LogInternalLink";
import {
   CardSplit,
   Container,
   ContentContainer,
   FeatureContainer,
   GreyContentCard,
   GreyContentContainer,
   GreyContentContainerInner,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
   TabSection,
} from "../../common/components/WebLayoutComponents";
import { Api } from "../../services/Api";
import { useCallback, useState, useEffect } from "react";
import { LOCKER_LOCATION_PAGE_URL } from "../../config/pages";
import { Helmet } from "react-helmet";

export const Business = () => {
   const api = new Api();
   api.setup();

   const [csvDate, setCSVDate] = useState();

   const getCSVDownload = async () => {
      return await api.getCSVFile().then(response => {
         const blob = new Blob([response.data], { type: "text/csv" });
         const url = URL.createObjectURL(blob);
         const a = document.createElement("a");
         a.href = url;
         a.download = "USPSSmartLockersActiveAll.csv";
         a.style.display = "none";
         document.body.appendChild(a);
         a.click();
         a.remove();
         URL.revokeObjectURL(url);
      });
   };

   const getCSVLastUpdate = useCallback(async () => {
      return await api.getCSVLastUpdate();
   }, [api]);

   useEffect(() => {
      (async () => {
         const csvUpdateResponse = await getCSVLastUpdate();
         setCSVDate(csvUpdateResponse.data);
      })();
   }, [getCSVLastUpdate]);

   return (
      <Container>
         <Helmet>
            <title>Smart Lockers for Business Shippers | USPS</title>
            <meta
               name="description"
               content="Business Shippers: Learn how you can offer your customers the option to pick up their packages at free, secure, self-service USPS Smart Lockers."
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@USPS" />
            <meta name="twitter:title" content="Smart Lockers for Business Shippers | USPS" />
            <meta
               name="twitter:description"
               content="Business Shippers: Learn how you can offer your customers the option to pick up their packages at free, secure, self-service USPS Smart Lockers."
            />
            <meta
               name="twitter:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />

            <meta property="og:url" content="https://www.uspssmartpackagelockers.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Smart Lockers for Business Shippers | USPS" />
            <meta
               property="og:description"
               content="Business Shippers: Learn how you can offer your customers the option to pick up their packages at free, secure, self-service USPS Smart Lockers."
            />
            <meta
               property="og:image"
               content="https://www.uspssmartpackagelockers.com/static/media/locker-open-with-boxes-mobile-min.088d45b0496b1d55acf6.jpg"
            />
         </Helmet>
         <HeroContainer className="business">
            <HeroInnerContainer>
               <HeroImage src={mobileHero} alt="Business Tab Hero Image" />
               <HeroContentContainer>
                  <h1>USPS Smart Lockers for Business</h1>
                  <p className="hero-width">
                     Unlock a new delivery method for you and your customers with the USPS<sup>&reg;</sup> Smart Locker
                     program.
                  </p>
                  <LogInternalLink primary outline="white" to={LOCKER_LOCATION_PAGE_URL}>
                     Find a Locker Location
                  </LogInternalLink>
               </HeroContentContainer>
            </HeroInnerContainer>
         </HeroContainer>
         <ContentContainer className="full">
            <FeatureContainer>
               <h2>A New Package Locker Service</h2>
               <p className="content-width">
                  The United States Postal Service is currently deploying a new package locker service that enables
                  shippers to offer their customers an alternative free, secure, and convenient package delivery option.
               </p>
               <p className="content-width">
                  By integrating with Smart Lockers, shippers can offer their customers the option to ship packages to a
                  USPS Smart Locker, while maintaining control over the customer experience.
               </p>
            </FeatureContainer>
         </ContentContainer>
         <ContentContainer>
            <GreyContentCard>
               <CardSplit className="image">
                  <img src={businessBenefits} alt="Man leaning over to work on laptop" />
               </CardSplit>
               <CardSplit className="text vertical-center">
                  <h3>Benefits to Your Business</h3>
                  <p>Explore the benefits of integrating Smart Lockers into your existing shipping strategies:</p>
                  <ul>
                     <li>
                        <strong>Eliminate Costs:</strong> Avoid costs associated with missing or stolen packages.
                     </li>
                     <li>
                        <strong>Reduce Failed Delivery Attempts:</strong> Reduce the number and costs of failed
                        deliveries - instead, packages can be delivered direct to a Smart Locker.
                     </li>
                     <li>
                        <strong>Increase Customer Satisfaction:</strong> Offer customers a self-service tool that
                        enhances the customer experience by providing flexible shipping and delivery options.
                     </li>
                     <li>
                        <strong>Build Brand Loyalty:</strong> Reduce customer anxiety over high-value or sensitive
                        packages by ensuring they are delivered safely.
                     </li>
                  </ul>
               </CardSplit>
            </GreyContentCard>
         </ContentContainer>
         <GreyContentContainer>
            <GreyContentContainerInner>
               <h2 style={{ textAlign: "center" }}>Resources to Get Started with USPS Smart Lockers</h2>
               <GreyContentCard className="mobile-reverse">
                  <CardSplit className="text vertical-center">
                     <p>
                        The Postal Service offers several tools to help your business offer USPS Smart Lockers as a
                        shipping destination for packages. <br />
                     </p>
                     <p>These tools are designed to work within your existing eCommerce and shipping practices.</p>
                     <ul>
                        <li>
                           <strong>
                              <a
                                 href="shippers-docs/USPS Smart Locker Overview.pdf"
                                 className="normal-link-color"
                                 download
                              >
                                 Smart Locker Overview
                              </a>{" "}
                              -
                           </strong>{" "}
                           General overview of the Smart Locker program, locker capabilities and use cases.
                        </li>
                        <li>
                           <strong>
                              <a
                                 href="shippers-docs/Getting Started With USPS Smart Lockers v1.9.pdf"
                                 className="normal-link-color"
                                 download="Getting Started With USPS Smart Lockers.pdf"
                              >
                                 Getting Started with Smart Lockers Guide
                              </a>{" "}
                              -
                           </strong>{" "}
                           Learn more about the Smart Locker Program and how to get started.
                        </li>
                     </ul>
                  </CardSplit>
                  <CardSplit>
                     <img src={codeScreen} alt="screen full of code" />
                  </CardSplit>
               </GreyContentCard>
            </GreyContentContainerInner>
         </GreyContentContainer>
         <ContentContainer>
            <GreyContentCard>
               <CardSplit>
                  <img src={lockerRetrieve} alt="Customer retrieving package from locker" />
               </CardSplit>
               <CardSplit className="text vertical-center">
                  <h3>How to Find a Locker</h3>
                  <p>
                     USPS supports multiple ways for package shippers to integrate USPS Smart Lockers into their
                     eCommerce Customer Experience.
                  </p>
                  <ul>
                     <li>
                        <strong>
                           <a
                              href="https://www.usps.com/business/web-tools-apis/get-locker.htm"
                              className="normal-link-color"
                              target="blank"
                           >
                              GetLockerInfo API
                           </a>{" "}
                           -
                        </strong>{" "}
                        Retrieve and store information for all operational smart lockers that match the optional
                        filters.
                     </li>
                     <li>
                        <strong>
                           <button onClick={getCSVDownload} className="link-no-href">
                              Locker Location CSV File
                           </button>{" "}
                           -
                        </strong>{" "}
                        Downloadable CSV file of all active Smart Locker locations.
                     </li>
                     <li className="no-bullet">
                        <strong>Last Refresh Date:</strong>{" "}
                        {csvDate &&
                           csvDate.substring(5, 7) + "/" + csvDate.substring(8) + "/" + csvDate.substring(0, 4)}
                        {!csvDate && "Not available"}
                     </li>
                     <li className="no-bullet">
                        <i>
                           <strong>Note:</strong>
                        </i>{" "}
                        ZIP Codes<sup>&trade;</sup> have leading zeros and should be formatted as text.
                     </li>
                     <li>
                        <strong>
                           <a
                              href="shippers-docs\USPS Smart Locker Installations_July2024.pdf"
                              className="normal-link-color"
                              download
                           >
                              USPS Smart Locker Network
                           </a>{" "}
                           -
                        </strong>{" "}
                        PDF list of all current and future locker deployment locations.
                     </li>
                  </ul>
               </CardSplit>
            </GreyContentCard>
         </ContentContainer>
         <GreyContentContainer>
            <GreyContentContainerInner>
               <GreyContentCard className="mobile-reverse">
                  <CardSplit className="text vertical-center">
                     <h3>How to Create Shipping Labels and Manifest Packages</h3>
                     <p>
                        Shippers can easily create shipping labels and manifest packages destined for Smart Lockers by
                        adding a few new values to one of the two existing labelling and manifesting tools listed below.
                     </p>
                     <ul>
                        <li>
                           <strong>Domestic Label API -</strong> Shippers can create package labels and manifest
                           packages using the domestic label API. See{" "}
                           <strong>
                              <a
                                 href="https://developer.usps.com/api/71"
                                 className="normal-link-color"
                                 target="_blank"
                                 rel="noreferrer"
                              >
                                 See Domestic Label 3.0 API documentation
                              </a>
                           </strong>{" "}
                           for instructions on how to use the domestic label API to generate labels for packages
                           destined to a Smart Locker.
                        </li>
                        <li>
                           <strong>Shipping Services file -</strong> Shippers can create their own labels and add the
                           following information to the electronic Shipping Services File (SSF) to manifest packages to
                           a Smart Locker.
                           <ul>
                              <li>
                                 <strong>
                                    <a
                                       href="https://postalpro.usps.com/modules/custom/postalpro_doc_viewer/web/viewer.html?file=/mnt/glusterfs/2023-10/Pub%20199_v28_10102023.pdf#%5B%7B%22num%22%3A430%2C%22gen%22%3A0%7D%2C%7B%22name%22%3A%22XYZ%22%7D%2C33%2C570%2C0%5D"
                                       className="normal-link-color"
                                       target="_blank"
                                       rel="noreferrer"
                                    >
                                       Appendix C - Shipping Services File 2.0
                                    </a>{" "}
                                    -
                                 </strong>{" "}
                                 outlines information needed to manifest packages.
                              </li>
                              <li>
                                 Updates to the Shipping Services File (SSF) are documented in the{" "}
                                 <a
                                    href="shippers-docs\Getting Started With USPS Smart Lockers v1.9.pdf"
                                    className="normal-link-color"
                                    download="Getting Started With USPS Smart Lockers.pdf"
                                 >
                                    <strong>Getting Started with Smart Lockers Guide</strong>
                                 </a>{" "}
                                 (page 4).
                              </li>
                           </ul>
                        </li>
                        <li>
                           <strong>Click-N-Ship -</strong> Shippers can use the enhanced Click-N-Ship service to print
                           custom shipping labels for packages destined to Smart Lockers. The enhanced Click-N-Ship
                           service enables shippers to print postage from home or office, making it even easier to ship
                           packages direct to Smart Lockers. For more information on enhanced Click-N-Ship, please{" "}
                           <a
                              href="https://www.usps.com/ship/online-shipping.htm"
                              target="_blank"
                              rel="noreferrer"
                              className="normal-link-color"
                           >
                              <strong style={{ overflowWrap: "break-word" }}>visit Click-N-Ship</strong>
                           </a>
                           .
                        </li>
                     </ul>
                  </CardSplit>
                  <CardSplit>
                     <img src={labelApply} alt="Label being applyed to package" />
                  </CardSplit>
               </GreyContentCard>
            </GreyContentContainerInner>
         </GreyContentContainer>
         <ContentContainer style={{ paddingBottom: "40px" }}>
            <GreyContentCard>
               <CardSplit>
                  <img src={datastreamKeyboard} alt="Datastream flowing over keyboard" />
               </CardSplit>
               <CardSplit className="text vertical-center">
                  <h3>How to Get Information about Packages Shipped to a Smart Locker</h3>
                  <p>
                     The introduction of USPS Smart Lockers includes new visibility events that give package shippers
                     new insight into package recipient experience with their packages. New scan events include:
                  </p>

                  <ul>
                     <li>SE '01' &mdash; Delivered to a Smart Locker</li>
                     <li>SE '43' &mdash; Picked Up from Smart Parcel Locker</li>
                     <li>SE '01' &mdash; Held at PO - Removed from Smart Locker</li>
                     {/* Remove if change is confirmed.*/}
                     {/*<li>*/}
                     {/*   <strong>*/}
                     {/*      <a*/}
                     {/*         href="https://www.usps.com/business/web-tools-apis/track-and-confirm-api.htm"*/}
                     {/*         className="normal-link-color"*/}
                     {/*         target="_blank"*/}
                     {/*         rel="noreferrer"*/}
                     {/*      >*/}
                     {/*         Tracking API*/}
                     {/*      </a>{" "}*/}
                     {/*      -*/}
                     {/*   </strong>{" "}*/}
                     {/*   Provides tracking status and delivery information for USPS packages.*/}
                     {/*</li>*/}
                     {/*<li>*/}
                     {/*   <strong>*/}
                     {/*      <a*/}
                     {/*         href="https://postalpro.usps.com/modules/custom/postalpro_doc_viewer/web/viewer.html?file=/mnt/glusterfs/2023-10/Pub%20199_v28_10102023.pdf#%5B%7B%22num%22%3A645%2C%22gen%22%3A0%7D%2C%7B%22name%22%3A%22XYZ%22%7D%2C48%2C562%2C0%5D"*/}
                     {/*         className="normal-link-color"*/}
                     {/*         target="_blank"*/}
                     {/*         rel="noreferrer"*/}
                     {/*      >*/}
                     {/*         Shipping Services Response File*/}
                     {/*      </a>{" "}*/}
                     {/*      -*/}
                     {/*   </strong>{" "}*/}
                     {/*   Reference Appendix G-4 (USPS Domestic Tracking Scan Events) for more information on package*/}
                     {/*   visibility events.*/}
                     {/*</li>*/}
                  </ul>
                  <p>
                     For more information on smart locker package visibility, see{" "}
                     <strong>
                        <a
                           href="https://www.usps.com/business/web-tools-apis/track-and-confirm-api.htm"
                           className="normal-link-color"
                           target="_blank"
                           rel="noreferrer"
                        >
                           Tracking API
                        </a>
                     </strong>{" "}
                     and{" "}
                     <strong>
                        <a
                           href="https://postalpro.usps.com/modules/custom/postalpro_doc_viewer/web/viewer.html?file=/mnt/glusterfs/2023-10/Pub%20199_v28_10102023.pdf#%5B%7B%22num%22%3A645%2C%22gen%22%3A0%7D%2C%7B%22name%22%3A%22XYZ%22%7D%2C48%2C562%2C0%5D"
                           className="normal-link-color"
                           target="_blank"
                           rel="noreferrer"
                        >
                           Shipping Services Response File
                        </a>
                     </strong>
                     .
                  </p>
               </CardSplit>
            </GreyContentCard>
         </ContentContainer>
      </Container>
   );
};

export default Business;
