import { useContext, useEffect, useState } from "react";

import { Api } from "../../services/Api";
import AuthContext from "../../services/AuthContext";

import { Spinner, Theme, ToggleSwitch } from "@informed-innovations/components";
import styled from "styled-components";

import { Modal } from "../../common/components/Modal";
import { default as EditIcon } from "../../static/img/edit_icon.png";
import { DeleteIcon } from "../../common/components/icons/DeleteIcon";
import { ReactComponent as Chevron } from "../../static/img/chevron.svg";
import { MobileBreakpoint } from "../../config/constants";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 40px;

   .edit-icon-modal {
      height: 25px;
      margin: 0 5px;
   }

   .spinner {
      height: 25px;
   }
`;

const ContentContainer = styled.div`
   margin: 0 auto;
   max-width: 1365px;
   margin-bottom: 60px;

   padding: 0 40px;
   width: 100%;

   .mobile-center {
      flex-direction: row;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      margin-bottom: 40px;
   }

   .admin-console-title {
      margin: 20px 0;
   }

   .data-table-section {
      margin-bottom: 40px;
   }

   .edit-icon {
      height: 25px;
      margin-right: 20px;
      cursor: pointer;

      @media screen and (max-width: ${MobileBreakpoint}) {
         margin-right: 20px;
         margin-left: 20px;
      }
   }

   .mobile-row {
      display: flex;
      color: ${Theme.colors.black};
      font-size: 16px;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
   }

   .mobile-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: ${Theme.colors.black};
      font-size: 16px;
      width: 100%;
      text-align: center;
      margin-top: 20px;
   }
`;

const Row = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 25px;
   color: ${Theme.colors.black};
   font-size: 16px;
   width: 100%;

   > * {
      margin: 0;
   }

   @media screen and (max-width: 960px) {
      flex-direction: column;
      flex-wrap: none;
      gap: 0;
      line-height: 2;
      justify-content: center;

      &.mobile-row {
         display: flex;
         color: ${Theme.colors.black};
         font-size: 16px;
         width: 100%;
         justify-content: space-between;
         margin-top: 20px;
      }
   }
`;

const MobileRow = styled.div`
   display: flex;
   color: ${Theme.colors.black};
   font-size: 16px;
   width: 100%;
   justify-content: space-between;
   margin-top: 20px;
`;

const MobileEntryContainer = styled.div`
   display: flex;
   color: ${Theme.colors.black};
   font-size: 16px;
   width: 100%;
   margin-top: 20px;

   .mobile-switch {
      margin-left: auto;
      display: flex;
   }
`;

const MobileCenter = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   color: ${Theme.colors.black};
   font-size: 16px;
   width: 100%;
   text-align: center;
   margin-top: 20px;
`;

const Col = styled.div`
   display: block;
   width: 100%;

   &.flex {
      display: flex;
   }
`;

const Divider = styled.hr`
   border-bottom: 1px solid #dfdfdf;
`;

const FormContainer = styled.div`
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 960px) {
      flex-direction: column;
   }
`;

const FormInnerContainer = styled.div`
   justify-content: between;
   gap: 25px;
   color: ${Theme.colors.black};
   font-size: 16px;
   margin: 0 auto;

   @media screen and (max-width: 960px) {
      flex-direction: column;
      flex-wrap: none;
      gap: 20px;
      line-height: 2;
      justify-content: center;
      > * {
         max-width: 100%;
         &:not(:last-child) {
            padding-right: 0px;
         }
      }
   }

   .submit-button {
      border: none;
      border-radius: 5px;
      color: white;
      background: #343366;
      font-weight: bold;
      padding: 5px 15px;
      margin-left: 10px;
   }

   .submit-button:hover {
      color: #333366;
      background: #d3d3d3;
   }

   .submit-disabled {
      background: #d3d3d3;
      border: none;
      border-radius: 5px;
      color: #343366;
      font-weight: bold;
      padding: 5px 15px;
      margin-left: 10px;
   }
`;

const FormInputContainer = styled.div`
   display: flex;
   flex-direction: column;

   @media screen and (max-width: 960px) {
      padding: 0;
   }

   p {
      margin: 0;
   }

   .form-input-invalid {
      color: red;
      width: 183px;
   }

   .form-input-valid {
      display: none;
      height: 18px;
   }

   .dropdown-form-text-input {
      width: 140px;
   }

   .form-text-input-invalid {
      border-color: red;
   }

   .submit-button-active {
      padding: 12px 10px;
      margin: 24px 0 auto 0;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      background: #343366;
      color: white;
      font-weight: bold;
   }

   .submit-button-active:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .submit-button {
      padding: 12px 10px;
      margin-top: 24px;
      margin-bottom: auto;
      border: none;
      border-radius: 3px;
      min-width: 100px;
      background: #d3d3d3;
      color: #343366;
      font-weight: bold;
   }

   .input-dropdown {
      border: 1px solid black;
      text-align: left;
      display: flex;

      input {
         border: none;
         outline: none;
      }

      .svg-holder {
         margin-left: auto;
         margin-right: 10px;
         transition: opacity 150ms ease-in-out;
         opacity: 1;
         width: fit-content;
      }

      .svg-holder svg {
         height: 20px;
         width: 20px;
         transform: rotate(90deg);
      }
   }

   .dropdown-active {
      opacity: 1;
      pointer-events: auto;
      display: flex;
      list-style: none;
      flex-direction: column;
      position: absolute;
      transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
      z-index: 3;
      background: field;
      padding: 0;
      margin-top: 53.2px;
      width: 183.2px;
      text-align: left;

      @media screen and (max-width: 960px) {
         margin-top: 66px;
      }

      li:hover {
         background: #343366;
         color: white;
      }
   }

   .qr-item-dropdown {
      display: flex;
      opacity: 0;
      list-style: none;
      flex-direction: column;
      pointer-events: none;
      position: absolute;
      transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
      z-index: 3;
      background: field;
      padding: 0;
      margin-top: 53.2px;
      width: 183.2px;
      text-align: left;

      @media screen and (max-width: 960px) {
         margin-top: 66px;
      }
   }
`;

function Console() {
   const api = new Api();
   api.setup();

   const [loadingData, setLoadingData] = useState(false);
   const [loading, setLoading] = useState(true);
   const [flags, setFlags] = useState();
   const [addFlagModal, setAddFlagModal] = useState(false);
   const [deleteFlagModal, setDeleteFlagModal] = useState(false);
   const [deleteFlagData, setDeleteFlagData] = useState(["", ""]);
   const [addFlagName, setAddFlagName] = useState("");
   const [addFlagDescription, setAddFlagDescription] = useState("");
   const [addFlagStatus, setAddFlagStatus] = useState(false);
   const [shows, setShows] = useState();
   const [addShowModal, setAddShowModal] = useState(false);
   const [deleteShowModal, setDeleteShowModal] = useState(false);
   const [deleteShowData, setDeleteShowData] = useState(["", ""]);
   const [addShowName, setAddShowName] = useState("");
   const [addShowId, setAddShowId] = useState("");
   const [addShowCity, setAddShowCity] = useState("");
   const [addShowState, setAddShowState] = useState("");
   const [addShowUrl, setAddShowUrl] = useState("");
   const [addShowStartDate, setAddShowStartDate] = useState("");
   const [addShowEndDate, setAddShowEndDate] = useState("");
   const [addShowStatus, setAddShowStatus] = useState(false);
   const [stateFormatError, setStateFormatError] = useState(false);
   const [errorModal, setErrorModal] = useState(false);
   const [repeatShowModal, setRepeatShowModal] = useState(false);
   const [editFlagModalOpen, setEditFlagModalOpen] = useState(false);
   const [editShowModalOpen, setEditShowModalOpen] = useState(false);
   const [qrCodes, setQrCodes] = useState();
   const [addQrCodeModal, setAddQrCodeModal] = useState(false);
   const [addQrCode, setAddQrCode] = useState("");
   const [addQrCodeShowId, setAddQrCodeShowId] = useState("");
   const [addQrCodeItem, setAddQrCodeItem] = useState("");
   const [addQrCodeStatus, setAddQrCodeStatus] = useState(false);
   const [editQrCodeModalOpen, setEditQrCodeModalOpen] = useState(false);
   const [deleteQrCodeModal, setDeleteQrCodeModal] = useState(false);
   const [deleteQrCodeData, setDeleteQrCodeData] = useState(["", ""]);
   const [qrCodeItemDropdownActive, setQrCodeItemDropdownActive] = useState(false);
   const [registeredShowModal, setRegisteredShowModal] = useState(false);
   const [qrCodeIdError, setQrCodeIdError] = useState(false);
   const [qrCodeExistsModal, setQrCodeExistsModal] = useState(false);
   const [updatingFlag, setUpdatingFlag] = useState();
   const [updatingShow, setUpdatingShow] = useState();
   const [updatingQrCode, setUpdatingQrCode] = useState();
   const [screenSize, setScreenSize] = useState(getCurrentDimension());

   const { handleLogout } = useContext(AuthContext);

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   const getFeatureFlags = async () => {
      return await api.getFeatureFlags();
   };

   const updateFeatureFlags = async (flagName, description, event, index) => {
      setUpdatingFlag(index);
      await api.updateFeatureFlag(flagName, event.target.checked, description).catch(resp => setErrorModal(true));
      setFlags(await getFeatureFlags());
      setUpdatingFlag();
   };

   const deleteFeatureFlag = async flagId => {
      await api.deleteFeatureFlag(flagId).then(async () => {
         setFlags(await getFeatureFlags());
         setDeleteFlagModal(false);
      });
   };

   const addFeatureFlags = async (name, status, description) => {
      return await api.updateFeatureFlag(name, status, description);
   };

   const getAllTradeShows = async () => {
      return await api.getAllTradeShows();
   };

   const updateTradeShow = async data => {
      const [showName, showId, city, state, url, startDate, endDate, event, index] = data;
      setUpdatingShow(index);
      await api
         .updateTradeShow(showName, showId, city, state, url, event.target.checked, startDate, endDate)
         .catch(resp => setErrorModal(true));
      setShows(await getAllTradeShows());
      setUpdatingShow();
   };

   const addTradeShow = async data => {
      const [name, id, city, state, url, status, startDate, endDate] = data;
      return await api.updateTradeShow(name, id, city, state, url, status, startDate, endDate);
   };

   const deleteTradeShow = async showId => {
      return await api.deleteTradeShow(showId).then(async resp => {
         setShows(await getAllTradeShows());
         setDeleteShowModal(false);
         if (resp.data.substring(0, 15) === "Trade Show with") {
            setRegisteredShowModal(true);
         }
      });
   };

   const getAllQrCodes = async () => {
      return await api.getAllQrCodes();
   };

   const addQrCodeEntry = async (code, id, item, status) => {
      let noUpdate = false;
      if (!editQrCodeModalOpen) {
         const qrCodeData = await getAllQrCodes();
         Object.values(qrCodeData.data).forEach(val => {
            if (val["qr-code"] === code && val["show-id"] === id) {
               noUpdate = true;
            }
         });
      }
      if (noUpdate) {
         setQrCodeExistsModal(true);
      } else {
         await api.updateQrCode(code, id, item, status).then(async resp => {
            if (resp.status === 400) setQrCodeIdError(true);
            setQrCodes(await getAllQrCodes());
         });
      }
   };

   const updateQrCode = async (code, showId, item, event, index) => {
      setUpdatingQrCode(index);
      await api.updateQrCode(code, showId, item, event.target.checked).catch(resp => setErrorModal(true));
      setQrCodes(await getAllQrCodes());
      setUpdatingQrCode();
   };

   const deleteQrCode = async data => {
      await api.deleteQrCode(data[0], data[1]).then(() => {
         setLoading(true);
         setDeleteQrCodeModal(false);
      });
   };

   useEffect(() => {
      if (loading) {
         (async () => {
            setFlags(await getFeatureFlags());
            setShows(await getAllTradeShows());
            setQrCodes(await getAllQrCodes());
            setLoading(false);
         })();
      }
   }, [loading]);

   function sortFlagByName(a, b) {
      if (a.flagName > b.flagName) {
         return 1;
      } else if (b.flagName > a.flagName) {
         return -1;
      } else {
         return 0;
      }
   }

   function sortShowByName(a, b) {
      if (a["show-name"] > b["show-name"]) {
         return 1;
      } else if (b["show-name"] > a["show-name"]) {
         return -1;
      } else {
         return 0;
      }
   }

   function sortQrCodeById(a, b) {
      if (a["show-id"] > b["show-id"]) {
         return 1;
      } else if (b["show-id"] > a["show-id"]) {
         return -1;
      } else {
         return 0;
      }
   }

   const handleQrItemChange = item => {
      setAddQrCodeItem(item);
      setQrCodeItemDropdownActive(false);
   };

   const openEditFlagModal = data => {
      setEditFlagModalOpen(true);
      setAddFlagModal(true);
      setAddFlagName(data.flagName);
      setAddFlagDescription(data.flagDescription);
      setAddFlagStatus(data.flagValue);
   };

   const openEditShowModal = data => {
      setEditShowModalOpen(true);
      setAddShowModal(true);
      setAddShowName(data["show-name"]);
      setAddShowId(data["show-id"]);
      setAddShowCity(data["city"]);
      setAddShowState(data["state"]);
      setAddShowUrl(data["url"]);
      setAddShowStartDate(data["start-date"]);
      setAddShowEndDate(data["end-date"]);
      setAddShowStatus(data["contest-flag"]);
   };

   const openEditQrCodeModal = data => {
      setEditQrCodeModalOpen(true);
      setAddQrCodeModal(true);
      setAddQrCode(data["qr-code"]);
      setAddQrCodeShowId(data["show-id"]);
      setAddQrCodeItem(data["item"]);
      setAddQrCodeStatus(data["status"] === "A" ? true : false);
   };

   const handleAddFlagCancel = e => {
      e.preventDefault();
      setAddFlagModal(false);
      setEditFlagModalOpen(false);
      resetAddFlagForm();
   };

   const handleAddShowCancel = e => {
      e.preventDefault();
      setAddShowModal(false);
      setEditShowModalOpen(false);
      resetAddShowForm();
   };

   const handleAddQrCancel = e => {
      e.preventDefault();
      setAddQrCodeModal(false);
      setEditQrCodeModalOpen(false);
      resetAddQrCodeForm();
   };

   const resetAddFlagForm = () => {
      setAddFlagName("");
      setAddFlagDescription("");
      setAddFlagStatus(false);
   };

   const resetAddShowForm = () => {
      setAddShowName("");
      setAddShowId("");
      setAddShowCity("");
      setAddShowState("");
      setAddShowUrl("");
      setAddShowStartDate("");
      setAddShowEndDate("");
      setAddShowStatus(false);
      setStateFormatError(false);
   };

   const resetAddQrCodeForm = () => {
      setAddQrCode("");
      setAddQrCodeShowId("");
      setAddQrCodeItem("");
      setAddQrCodeStatus(false);
   };

   const handleShowStateChange = e => {
      setAddShowState(e.target.value);
      if (!stateFormatError) {
         setStateFormatError(true);
      }
   };

   const handleErrorModal = () => {
      setAddShowModal(false);
      setErrorModal(true);
   };

   const handleRepeatShowModal = async resp => {
      if (resp.problem === "CLIENT_ERROR") {
         setRepeatShowModal(true);
      } else setShows(await getAllTradeShows());
   };

   const handleSubmit = async e => {
      e.preventDefault();
      setLoadingData(true);

      await addFeatureFlags(addFlagName, addFlagStatus, addFlagDescription)
         .then(async () => setFlags(await getFeatureFlags()))
         .catch(async () => handleErrorModal());

      setLoadingData(false);
      setAddFlagModal(false);
      resetAddFlagForm();
   };

   const handleTradeShowSubmit = async e => {
      e.preventDefault();
      setLoadingData(true);

      await addTradeShow([
         addShowName,
         addShowId,
         addShowCity,
         addShowState,
         addShowUrl,
         addShowStatus,
         addShowStartDate,
         addShowEndDate,
      ])
         .then(async resp => handleRepeatShowModal(resp))
         .catch(async () => handleErrorModal());

      setAddShowModal(false);
      setLoadingData(false);
      resetAddShowForm();
   };

   const handleQrCodeSubmit = async e => {
      e.preventDefault();
      setLoadingData(true);

      await addQrCodeEntry(addQrCode, addQrCodeShowId, addQrCodeItem, addQrCodeStatus).then(async () => {
         setQrCodes(await getAllQrCodes());
      });

      setEditQrCodeModalOpen(false);
      setAddQrCodeModal(false);
      setLoadingData(false);
      resetAddQrCodeForm();
   };

   function isSwitchToggled(qrCode) {
      return qrCode["status"] === "A";
   }

   function twoOptionString(watchedValue, trueText, falseText) {
      if (watchedValue) return trueText;
      else return falseText;
   }

   return (
      <Container>
         <Modal modalActive={addFlagModal} closeFunction={() => setAddFlagModal(false)}>
            <h2>{twoOptionString(editFlagModalOpen, "Update", "Add New")} Feature Flag</h2>
            <FormContainer>
               <FormInnerContainer>
                  <form id="addFlagForm" onSubmit={handleSubmit}>
                     <FormInputContainer>
                        <p>*Feature Flag Name</p>
                        <input
                           className="form-text-input"
                           type="text"
                           placeholder="Enter the name of the feature flag"
                           onInput={e => setAddFlagName(e.target.value)}
                           value={addFlagName}
                        />
                     </FormInputContainer>
                     <FormInputContainer>
                        <p>Description</p>
                        <input
                           className="form-text-input"
                           type="text"
                           placeholder="Feature flag description"
                           onInput={e => setAddFlagDescription(e.target.value)}
                           value={addFlagDescription}
                        />
                     </FormInputContainer>
                     <FormInputContainer>
                        <p>Status</p>
                        <ToggleSwitch
                           onLabel="On"
                           offLabel="Off"
                           value={addFlagName}
                           checked={addFlagStatus}
                           description={addFlagDescription}
                           onChange={() => setAddFlagStatus(!addFlagStatus)}
                        />
                     </FormInputContainer>
                     <button onClick={handleAddFlagCancel}>Cancel</button>
                     <input
                        type="submit"
                        value={twoOptionString(loadingData, "Saving...", "Submit")}
                        className={
                           !/^(?!\s*$).+/.test(addFlagName) || loadingData ? "submit-disabled" : "submit-button"
                        }
                        disabled={!/^(?!\s*$).+/.test(addFlagName) || loadingData}
                     />
                  </form>
               </FormInnerContainer>
            </FormContainer>
         </Modal>
         <Modal modalActive={addShowModal} closeFunction={() => setAddShowModal(false)}>
            <h2>{twoOptionString(editShowModalOpen, "Update", "Add New")} Trade Show</h2>
            <p>All fields are required</p>
            <FormContainer>
               <FormInnerContainer>
                  <form id="addShowForm" onSubmit={handleTradeShowSubmit}>
                     <Row>
                        <FormInputContainer>
                           <p>Trade Show Name</p>
                           <input
                              className="form-text-input"
                              type="text"
                              placeholder="Enter the name of trade show"
                              onChange={e => setAddShowName(e.target.value)}
                              value={addShowName}
                           />
                        </FormInputContainer>
                        <FormInputContainer>
                           <p>Show ID</p>
                           <input
                              className="form-text-input"
                              type="number"
                              placeholder="Enter an ID used for show reference"
                              onChange={e => setAddShowId(e.target.value)}
                              value={addShowId}
                              readOnly={editShowModalOpen}
                           />
                        </FormInputContainer>
                     </Row>
                     <Row>
                        <FormInputContainer>
                           <p>City</p>
                           <input
                              className="form-text-input"
                              type="text"
                              placeholder="Enter city of trade show"
                              onChange={e => setAddShowCity(e.target.value)}
                              value={addShowCity}
                           />
                        </FormInputContainer>
                        <FormInputContainer>
                           <p>State</p>
                           <input
                              className={
                                 /^[A-Z]{2}$/.test(addShowState) || !stateFormatError
                                    ? "form-text-input"
                                    : "form-text-input-invalid"
                              }
                              type="text"
                              placeholder="Enter state of trade show"
                              onChange={handleShowStateChange}
                              value={addShowState}
                           />
                           <p
                              className={
                                 /^[A-Z]{2}$/.test(addShowState) || !stateFormatError
                                    ? "form-input-valid"
                                    : "form-input-invalid"
                              }
                           >
                              Use state abbreviations. Ex: NY or CA
                           </p>
                        </FormInputContainer>
                     </Row>
                     <FormInputContainer>
                        <p>URL</p>
                        <input
                           className="form-text-input"
                           type="text"
                           placeholder="Enter URL for trade show"
                           onChange={e => setAddShowUrl(e.target.value)}
                           value={addShowUrl}
                        />
                     </FormInputContainer>
                     <Row>
                        <FormInputContainer>
                           <p>Start Date</p>
                           <input
                              className="form-text-input"
                              type="date"
                              onInput={e => setAddShowStartDate(e.target.value)}
                              value={addShowStartDate}
                           />
                        </FormInputContainer>
                        <FormInputContainer>
                           <p>End Date</p>
                           <input
                              className="form-text-input"
                              type="date"
                              onInput={e => setAddShowEndDate(e.target.value)}
                              value={addShowEndDate}
                           />
                        </FormInputContainer>
                     </Row>
                     <FormInputContainer>
                        <p>Status</p>
                        <ToggleSwitch
                           onLabel="On"
                           offLabel="Off"
                           value={addShowName}
                           checked={addShowStatus}
                           onChange={() => setAddShowStatus(!addShowStatus)}
                        />
                     </FormInputContainer>
                     <button onClick={handleAddShowCancel}>Cancel</button>
                     <input
                        type="submit"
                        className={
                           !/^(?!\s*$).+/.test(addShowName) ||
                           !/^(?!\s*$).+/.test(addShowId) ||
                           !/^(?!\s*$).+/.test(addShowCity) ||
                           !/^[A-Z]{2}$/.test(addShowState) ||
                           !/^(?!\s*$).+/.test(addShowUrl) ||
                           !/^(?!\s*$).+/.test(addShowStartDate) ||
                           !/^(?!\s*$).+/.test(addShowEndDate) ||
                           loadingData
                              ? "submit-disabled"
                              : "submit-button"
                        }
                        value={twoOptionString(loadingData, "Saving...", "Submit")}
                        disabled={
                           !/^(?!\s*$).+/.test(addShowName) ||
                           !/^(?!\s*$).+/.test(addShowId) ||
                           !/^(?!\s*$).+/.test(addShowCity) ||
                           !/^[A-Z]{2}$/.test(addShowState) ||
                           !/^(?!\s*$).+/.test(addShowUrl) ||
                           !/^(?!\s*$).+/.test(addShowStartDate) ||
                           !/^(?!\s*$).+/.test(addShowEndDate) ||
                           loadingData
                        }
                     />
                  </form>
               </FormInnerContainer>
            </FormContainer>
         </Modal>
         <Modal modalActive={addQrCodeModal} closeFunction={() => setAddQrCodeModal(false)}>
            <h2>{twoOptionString(editQrCodeModalOpen, "Update", "Add New")} QR Code</h2>
            <FormContainer>
               <FormInnerContainer>
                  <form id="addFlagForm" onSubmit={handleQrCodeSubmit}>
                     <FormInputContainer>
                        <p>QR code</p>
                        <input
                           className="form-text-input"
                           type="text"
                           placeholder="Enter the QR code"
                           onInput={e => setAddQrCode(e.target.value)}
                           value={addQrCode}
                        />
                     </FormInputContainer>
                     <FormInputContainer>
                        <p>Associated Show ID</p>
                        <input
                           className="form-text-input"
                           type="number"
                           placeholder="Enter the associated show's ID"
                           onInput={e => setAddQrCodeShowId(parseInt(e.target.value))}
                           value={addQrCodeShowId}
                           readOnly={editQrCodeModalOpen}
                        />
                     </FormInputContainer>
                     <FormInputContainer>
                        <p>Item Held</p>
                        <div
                           className="input-dropdown"
                           onClick={() => setQrCodeItemDropdownActive(!qrCodeItemDropdownActive)}
                           onKeyDown={() => setQrCodeItemDropdownActive(!qrCodeItemDropdownActive)}
                        >
                           <input
                              className="dropdown-form-text-input"
                              type="text"
                              placeholder="Item held in locker"
                              value={
                                 addQrCodeItem === "" ? "" : twoOptionString(addQrCodeItem === "T", "T-Shirt", "Socks")
                              }
                              readOnly
                           />
                           <div
                              className={qrCodeItemDropdownActive ? "input-dropdown-active svg-holder" : "svg-holder"}
                           >
                              <Chevron />
                           </div>
                        </div>
                        <ul className={qrCodeItemDropdownActive ? "dropdown-active" : "qr-item-dropdown"}>
                           <li onClick={() => handleQrItemChange("T")} onKeyDown={() => handleQrItemChange("T")}>
                              T-Shirt
                           </li>
                           <li onClick={() => handleQrItemChange("S")} onKeyDown={() => handleQrItemChange("S")}>
                              Socks
                           </li>
                        </ul>
                     </FormInputContainer>
                     <FormInputContainer>
                        <p>Active</p>
                        <ToggleSwitch
                           onLabel="Yes"
                           offLabel="No"
                           value={addQrCode}
                           checked={addQrCodeStatus}
                           onChange={() => setAddQrCodeStatus(!addQrCodeStatus)}
                        />
                     </FormInputContainer>
                     <button onClick={handleAddQrCancel}>Cancel</button>
                     <input
                        type="submit"
                        className={
                           !/^(?!\s*$).+/.test(addQrCode) ||
                           !/^(?!\s*$).+/.test(addQrCodeShowId) ||
                           !/^(?!\s*$).+/.test(addQrCodeItem) ||
                           loadingData
                              ? "submit-disabled"
                              : "submit-button"
                        }
                        value={twoOptionString(loadingData, "Saving...", "Submit")}
                        disabled={
                           !/^(?!\s*$).+/.test(addQrCode) ||
                           !/^(?!\s*$).+/.test(addQrCodeShowId) ||
                           !/^(?!\s*$).+/.test(addQrCodeItem) ||
                           loadingData
                        }
                     />
                  </form>
               </FormInnerContainer>
            </FormContainer>
         </Modal>
         <Modal modalActive={deleteFlagModal} closeFunction={() => setDeleteFlagModal(false)}>
            <Row>
               <Col>
                  <h1>Delete Feature Flag</h1>
                  <p>Are you sure you want to delete feature flag "{deleteFlagData[1]}"?</p>
                  <p>
                     Make sure that the flag is not being used anywhere before removing it or it could result in
                     breaking the site.
                  </p>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormContainer>
                     <button onClick={() => setDeleteFlagModal(false)}>Cancel</button>
                  </FormContainer>
               </Col>
               <Col>
                  <FormContainer>
                     <button onClick={() => deleteFeatureFlag(deleteFlagData[0])}>Delete</button>
                  </FormContainer>
               </Col>
            </Row>
         </Modal>
         <Modal modalActive={deleteShowModal} closeFunction={() => setDeleteShowModal(false)}>
            <Row>
               <Col>
                  <h1>Delete Trade Show</h1>
                  <p>Are you sure you want to delete trade show "{deleteShowData[1]}"?</p>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormContainer>
                     <button onClick={() => setDeleteShowModal(false)}>Cancel</button>
                  </FormContainer>
               </Col>
               <Col>
                  <FormContainer>
                     <button onClick={() => deleteTradeShow(deleteShowData[0])}>Delete</button>
                  </FormContainer>
               </Col>
            </Row>
         </Modal>
         <Modal modalActive={deleteQrCodeModal} closeFunction={() => setDeleteQrCodeModal(false)}>
            <Row>
               <Col>
                  <h1>Delete QR Code</h1>
                  <p>Are you sure you want to delete QR code "{deleteQrCodeData[1]}"?</p>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormContainer>
                     <button onClick={() => setDeleteQrCodeModal(false)}>Cancel</button>
                  </FormContainer>
               </Col>
               <Col>
                  <FormContainer>
                     <button onClick={() => deleteQrCode(deleteQrCodeData)}>Delete</button>
                  </FormContainer>
               </Col>
            </Row>
         </Modal>
         <Modal modalActive={errorModal} closeFunction={() => setErrorModal(false)}>
            <h1>Hmm..</h1>
            <p>It looks like something went wrong, please try again later.</p>
         </Modal>
         <Modal modalActive={repeatShowModal} closeFunction={() => setRepeatShowModal(false)}>
            <h1>Show Name Taken</h1>
            <p>
               That show name is already being used. To change ID associated with show name, delete existing show with
               desired name or rename the show you are creating to be unique.
            </p>
         </Modal>
         <Modal modalActive={registeredShowModal} closeFunction={() => setRegisteredShowModal(false)}>
            <h1>Show Already Registered</h1>
            <p>
               It looks like someone has already registered using a QR code with that show's ID. A show should not be
               deleted if it has been registered to.
            </p>
         </Modal>
         <Modal modalActive={qrCodeIdError} closeFunction={() => setQrCodeIdError(false)}>
            <h1>Show Id Doesn't Exist</h1>
            <p>It looks like the Show ID that you are trying to link this QR code to doesn't exist.</p>
         </Modal>
         <Modal modalActive={qrCodeExistsModal} closeFunction={() => setQrCodeExistsModal(false)}>
            <h1>QR Code Already Exists</h1>
            <p>
               The QR code that you are trying to add already exists, please edit using the
               <img src={EditIcon} alt="Edit Icon" className="edit-icon-modal" />
               button next to the QR code if you need to make changes.
            </p>
         </Modal>
         {screenSize.width > 960 && (
            <ContentContainer>
               <Row className="admin-console-title mobile-row">
                  <Col>
                     <h1>Admin</h1>
                  </Col>
                  <Col>
                     <button onClick={handleLogout}>Logout</button>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <h3>Feature Flags</h3>
                  </Col>
                  <Col>
                     <button onClick={() => setAddFlagModal(true)}>Add Feature Flag</button>
                  </Col>
               </Row>
               <Row className="data-table-section">
                  <Col>
                     {flags && (
                        <>
                           <Row>
                              <Col>
                                 <strong>Feature Flag</strong>
                              </Col>
                              <Col>
                                 <strong>Description</strong>
                              </Col>
                              <Col>
                                 <strong>Status</strong>
                              </Col>
                              <Col>
                                 <strong>Actions</strong>
                              </Col>
                           </Row>
                           <Divider />
                           {flags.data?.responseList?.sort(sortFlagByName).map((flag, index) => (
                              <Row key={flag.id}>
                                 <Col>{flag.flagName}</Col>
                                 <Col>{flag.flagDescription}</Col>
                                 <Col className="flex">
                                    <ToggleSwitch
                                       onLabel="On"
                                       offLabel="Off"
                                       checked={flag.flagValue}
                                       onChange={event =>
                                          updateFeatureFlags(flag.flagName, flag.flagDescription, event, index)
                                       }
                                    />
                                    {updatingFlag === index && <Spinner className="spinner" />}
                                 </Col>
                                 <Col>
                                    <img
                                       src={EditIcon}
                                       alt="Edit Icon"
                                       className="edit-icon"
                                       onClick={() => openEditFlagModal(flag)}
                                       onKeyDown={() => openEditFlagModal(flag)}
                                    />
                                    <DeleteIcon
                                       onClick={() => {
                                          setDeleteFlagData([flag.id, flag.flagName]);
                                          setDeleteFlagModal(true);
                                       }}
                                       style={{ width: 25, height: 25, cursor: "pointer" }}
                                    />
                                 </Col>
                              </Row>
                           ))}
                        </>
                     )}
                     {!flags && <>No feature flags found</>}
                  </Col>
               </Row>
               <Row className="trade-shows-title">
                  <Col>
                     <h3>Trade Shows</h3>
                  </Col>
                  <Col>
                     <button onClick={() => setAddShowModal(true)}>Add Trade Show</button>
                  </Col>
               </Row>
               <Row className="data-table-section">
                  <Col>
                     {shows && (
                        <>
                           <Row>
                              <Col>
                                 <strong>Show Name</strong>
                              </Col>
                              <Col>
                                 <strong>Show ID</strong>
                              </Col>
                              <Col>
                                 <strong>City</strong>
                              </Col>
                              <Col>
                                 <strong>State</strong>
                              </Col>
                              <Col>
                                 <strong>Start Date</strong>
                              </Col>
                              <Col>
                                 <strong>End Date</strong>
                              </Col>
                              <Col>
                                 <strong>Status</strong>
                              </Col>
                              <Col>
                                 <strong>Actions</strong>
                              </Col>
                           </Row>
                           <Divider />
                           {shows.data?.sort(sortShowByName).map((show, index) => (
                              <Row key={index}>
                                 <Col>{show["show-name"]}</Col>
                                 <Col>{show["show-id"]}</Col>
                                 <Col>{show["city"]}</Col>
                                 <Col>{show["state"]}</Col>
                                 <Col>{show["start-date"]}</Col>
                                 <Col>{show["end-date"]}</Col>
                                 <Col className="flex">
                                    <ToggleSwitch
                                       onLabel="On"
                                       offLabel="Off"
                                       checked={show["contest-flag"]}
                                       onChange={event =>
                                          updateTradeShow([
                                             show["show-name"],
                                             show["show-id"],
                                             show["city"],
                                             show["state"],
                                             show["url"],
                                             show["start-date"],
                                             show["end-date"],
                                             event,
                                             index,
                                          ])
                                       }
                                    />
                                    {updatingShow === index && <Spinner className="spinner" />}
                                 </Col>
                                 <Col>
                                    <img
                                       src={EditIcon}
                                       alt="Edit Icon"
                                       className="edit-icon"
                                       onClick={() => openEditShowModal(show)}
                                       onKeyDown={() => openEditShowModal(show)}
                                    />
                                    <DeleteIcon
                                       onClick={() => {
                                          setDeleteShowData([show["show-id"], show["show-name"]]);
                                          setDeleteShowModal(true);
                                       }}
                                       style={{ width: 25, height: 25, cursor: "pointer" }}
                                    />
                                 </Col>
                              </Row>
                           ))}
                        </>
                     )}
                     {!shows && <>No trade shows found</>}
                  </Col>
               </Row>
               <Row className="qr-code-title">
                  <Col>
                     <h3>QR Codes</h3>
                  </Col>
                  <Col>
                     <button onClick={() => setAddQrCodeModal(true)}>Add QR code</button>
                  </Col>
               </Row>
               <Row className="data-table-section">
                  <Col>
                     {qrCodes && (
                        <>
                           <Row>
                              <Col>
                                 <strong>QR code</strong>
                              </Col>
                              <Col>
                                 <strong>Show ID</strong>
                              </Col>
                              <Col>
                                 <strong>Item</strong>
                              </Col>
                              <Col>
                                 <strong>Active</strong>
                              </Col>
                              <Col>
                                 <strong>Actions</strong>
                              </Col>
                           </Row>
                           <Divider />
                           {qrCodes.data?.sort(sortQrCodeById).map((qrCode, index) => (
                              <Row key={index}>
                                 <Col>{qrCode["qr-code"]}</Col>
                                 <Col>{qrCode["show-id"]}</Col>
                                 <Col>{qrCode["item"] === "S" ? "Socks" : "T-Shirt"}</Col>
                                 <Col className="flex">
                                    <ToggleSwitch
                                       onLabel="Yes"
                                       offLabel="No"
                                       checked={isSwitchToggled(qrCode)}
                                       onChange={event =>
                                          updateQrCode(
                                             qrCode["qr-code"],
                                             qrCode["show-id"],
                                             qrCode["item"],
                                             event,
                                             index
                                          )
                                       }
                                    />
                                    {updatingQrCode === index && <Spinner className="spinner" />}
                                 </Col>
                                 <Col>
                                    <img
                                       src={EditIcon}
                                       alt="Edit Icon"
                                       className="edit-icon"
                                       onClick={() => openEditQrCodeModal(qrCode)}
                                       onKeyDown={() => openEditQrCodeModal(qrCode)}
                                    />
                                    <DeleteIcon
                                       onClick={() => {
                                          setDeleteQrCodeData([qrCode["show-id"], qrCode["qr-code"]]);
                                          setDeleteQrCodeModal(true);
                                       }}
                                       style={{ width: 25, height: 25, cursor: "pointer" }}
                                    />
                                 </Col>
                              </Row>
                           ))}
                        </>
                     )}
                     {!qrCodes && <>No QR codes found</>}
                  </Col>
               </Row>
            </ContentContainer>
         )}
         {screenSize.width <= 960 && (
            <ContentContainer>
               <MobileRow>
                  <h1>Admin</h1>
                  <button onClick={handleLogout}>Logout</button>
               </MobileRow>
               <MobileCenter>
                  <h3>Feature Flags</h3>
                  <button onClick={() => setAddFlagModal(true)}>Add Feature Flag</button>
               </MobileCenter>
               {flags && (
                  <>
                     <MobileRow>
                        <strong>Name</strong>
                        <strong>Status</strong>
                     </MobileRow>
                     {flags.data?.responseList?.map((flag, index) => (
                        <MobileEntryContainer key={index}>
                           {flag.flagName}
                           <img
                              src={EditIcon}
                              alt="Edit Icon"
                              className="edit-icon"
                              onClick={() => openEditFlagModal(flag)}
                              onKeyDown={() => openEditFlagModal(flag)}
                           />
                           <DeleteIcon
                              onClick={() => {
                                 setDeleteFlagData([flag.id, flag.flagName]);
                                 setDeleteFlagModal(true);
                              }}
                              style={{ width: 25, height: 25, cursor: "pointer" }}
                           />
                           <div className={screenSize.width <= 960 ? "mobile-switch" : ""}>
                              {updatingFlag === index && <Spinner className="spinner" />}
                              <ToggleSwitch
                                 onLabel="On"
                                 offLabel="Off"
                                 checked={flag.flagValue}
                                 onChange={event =>
                                    updateFeatureFlags(flag.flagName, flag.flagDescription, event, index)
                                 }
                              />
                           </div>
                        </MobileEntryContainer>
                     ))}
                  </>
               )}
               {!flags && <>No feature flags found</>}
               <MobileCenter>
                  <h3>Trade Shows</h3>
                  <button onClick={() => setAddShowModal(true)}>Add Trade Show</button>
               </MobileCenter>
               {shows && (
                  <>
                     <MobileRow>
                        <strong>Name</strong>
                        <strong>Status</strong>
                     </MobileRow>
                     {shows.data?.map((show, index) => (
                        <MobileEntryContainer key={index}>
                           {show["show-name"]}
                           <img
                              src={EditIcon}
                              alt="Edit Icon"
                              className="edit-icon"
                              onClick={() => openEditShowModal(show)}
                              onKeyDown={() => openEditShowModal(show)}
                           />
                           <DeleteIcon
                              onClick={() => {
                                 setDeleteShowData([show["show-id"], show["show-name"]]);
                                 setDeleteShowModal(true);
                              }}
                              style={{ width: 25, height: 25, cursor: "pointer" }}
                           />
                           <div className={screenSize.width <= 960 ? "mobile-switch" : ""}>
                              {updatingShow === index && <Spinner className="spinner" />}
                              <ToggleSwitch
                                 onLabel="On"
                                 offLabel="Off"
                                 checked={show["contest-flag"]}
                                 onChange={event =>
                                    updateTradeShow([
                                       show["show-name"],
                                       show["show-id"],
                                       show["city"],
                                       show["state"],
                                       show["url"],
                                       show["start-date"],
                                       show["end-date"],
                                       event,
                                       index,
                                    ])
                                 }
                              />
                           </div>
                        </MobileEntryContainer>
                     ))}
                  </>
               )}
               {!shows && <>No trade shows found</>}
               <MobileCenter>
                  <h3>QR Codes</h3>
                  <button onClick={() => setAddQrCodeModal(true)}>Add QR code</button>
               </MobileCenter>
               {qrCodes && (
                  <>
                     <MobileRow>
                        <strong>Name</strong>
                        <strong>Active</strong>
                     </MobileRow>
                     {qrCodes.data?.map((qrCode, index) => (
                        <MobileEntryContainer key={index}>
                           {qrCode["qr-code"]}
                           <img
                              src={EditIcon}
                              alt="Edit Icon"
                              className="edit-icon"
                              onClick={() => openEditQrCodeModal(qrCode)}
                              onKeyDown={() => openEditQrCodeModal(qrCode)}
                           />
                           <DeleteIcon
                              onClick={() => {
                                 setDeleteQrCodeData([qrCode["show-id"], qrCode["qr-code"]]);
                                 setDeleteQrCodeModal(true);
                              }}
                              style={{ width: 25, height: 25, cursor: "pointer" }}
                           />
                           <div className="mobile-switch">
                              {updatingQrCode === index && <Spinner className="spinner" />}
                              <ToggleSwitch
                                 onLabel="Yes"
                                 offLabel="No"
                                 checked={isSwitchToggled(qrCode)}
                                 onChange={event =>
                                    updateQrCode(qrCode["qr-code"], qrCode["show-id"], qrCode["item"], event, index)
                                 }
                              />
                           </div>
                        </MobileEntryContainer>
                     ))}
                  </>
               )}
               {!qrCodes && <>No QR codes found</>}
            </ContentContainer>
         )}
      </Container>
   );
}

export default Console;
