import styled from "styled-components";
import { capitalizeWords, getStateTwoDigitCode } from "../../common/utils";
import RightChevron from "../../static/img/right-chevron.PNG";

const ListContainer = styled.div`
   max-width: 1132px;
   margin: 0 auto;

   hr {
      border: 1px solid #333366;
   }
`;

const StateContainer = styled.div`
   h2 {
      margin-bottom: 12px;
      text-align: left;
   }
`;

const CityContainer = styled.div`
   max-width: 911px;
   column-count: 2;
   margin: 40px auto 60px;

   @media screen and (max-width: 480px) {
      column-count: 1;
   }
`;

const CityBlock = styled.div`
   display: flex;
   border-bottom: 1px solid #333366;
   padding-bottom: 25px;
   margin-bottom: 20px;
   cursor: pointer;

   h4 {
      margin: 0;
      color: #3573b1;
      text-decoration: underline;
      font-weight: normal;
   }

   img {
      width: 7px;
      height: 14px;
      margin-top: 5px;
      margin-left: auto;
      margin-right: 22px;
   }
`;

function LockerSearchWithFilter({ lockers, onCityClick, locationInput }) {
   const locationInputSplit = locationInput.split(",");
   const searchItem = locationInputSplit[0];

   if (locationInput === "") {
      return (
         <>
            <hr style={{ border: "1px solid #DFDFDF", marginBottom: "40px" }} />
            <ListContainer id="locker-search-list-container">
               {lockers.data?.map((state, index) => {
                  return (
                     <StateContainer key={index} id={state.state[0]}>
                        <h2>{capitalizeWords(state.state)}</h2>
                        <hr />
                        <CityContainer>
                           {state.cities.map((city, index) => {
                              return (
                                 <CityBlock
                                    key={index}
                                    onClick={() =>
                                       onCityClick(
                                          capitalizeWords(city) +
                                             ", " +
                                             getStateTwoDigitCode(capitalizeWords(state.state))
                                       )
                                    }
                                 >
                                    <h4>{capitalizeWords(city)}</h4>
                                    <img src={RightChevron} alt="right chevron" />
                                 </CityBlock>
                              );
                           })}
                        </CityContainer>
                     </StateContainer>
                  );
               })}
            </ListContainer>
         </>
      );
   } else {
      let matchFound = false;
      return (
         <>
            <ListContainer id="locker-search-list-container">
               {lockers.data?.map((state, index) => {
                  let filteredCities = state.cities.filter(city =>
                     city.toLowerCase().startsWith(searchItem.toLowerCase())
                  );
                  if (filteredCities.length !== 0) {
                     if (!matchFound) matchFound = true;
                     return (
                        <StateContainer key={index} id={state.state[0]}>
                           <h2>{capitalizeWords(state.state)}</h2>
                           <hr />
                           <CityContainer>
                              {filteredCities.map((city, index) => {
                                 return (
                                    <CityBlock
                                       key={index}
                                       onClick={() =>
                                          onCityClick(
                                             capitalizeWords(city) +
                                                ", " +
                                                getStateTwoDigitCode(capitalizeWords(state.state))
                                          )
                                       }
                                    >
                                       <h4>
                                          <strong>{capitalizeWords(city).substr(0, searchItem.length)}</strong>
                                          {capitalizeWords(city).substr(searchItem.length)}
                                       </h4>
                                       <img src={RightChevron} alt="right chevron" />
                                    </CityBlock>
                                 );
                              })}
                           </CityContainer>
                        </StateContainer>
                     );
                  } else {
                     return undefined;
                  }
               })}
               {lockers.data?.map((state, index) => {
                  if (
                     state.state.toLowerCase().startsWith(searchItem.toLowerCase()) ||
                     getStateTwoDigitCode(capitalizeWords(state.state)).toLowerCase() === searchItem.toLowerCase()
                  ) {
                     if (!matchFound) matchFound = true;
                     return (
                        <StateContainer key={index} id={state.state[0]}>
                           <h2>{capitalizeWords(state.state)}</h2>
                           <hr />
                           <CityContainer>
                              {state.cities.map((city, index) => {
                                 return (
                                    <CityBlock
                                       key={index}
                                       onClick={() =>
                                          onCityClick(
                                             capitalizeWords(city) +
                                                ", " +
                                                getStateTwoDigitCode(capitalizeWords(state.state))
                                          )
                                       }
                                    >
                                       <h4>{capitalizeWords(city)}</h4>
                                       <img src={RightChevron} alt="right chevron" />
                                    </CityBlock>
                                 );
                              })}
                           </CityContainer>
                        </StateContainer>
                     );
                  } else {
                     return undefined;
                  }
               })}
               {!matchFound && (
                  <StateContainer id="no-match-container">
                     <h4 style={{ fontWeight: "normal", marginBottom: 30 + "px" }}>
                        No exact matches for "{locationInput}"
                     </h4>
                     <hr style={{ marginBottom: 30 + "px" }} />
                     <h4 style={{ marginBottom: 30 + "px" }}>
                        Enter a complete Zip or City & State to find possible locations within your radius
                     </h4>
                     <hr />
                  </StateContainer>
               )}
            </ListContainer>
         </>
      );
   }
}

export default LockerSearchWithFilter;
