import styled from "styled-components";
import landingHero from "../../static/img/locker-open-with-boxes-min.jpg";
import customerHero from "../../static/img/customer-hero.jpg";
import merchantHero from "../../static/img/shippers-hero.jpg";
import lockerLookupHero from "../../static/img/locker-lookup-hero-blend-min.png";
import businessHero from "../../static/img/business-hero.jpg";
import { Theme } from "@informed-innovations/components";

export const USPSPrimary = "#333366";

export const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 40px;
   color: rgb(0, 0, 0);

   h1 {
      font-size: 32px;
   }

   h2 {
      font-size: 28px;
   }

   h3 {
      font-size: 22px;
   }

   h4 {
      font-size: 18px;
   }

   .smallest-mb {
      margin-bottom: 10px;
   }

   .smaller-mb {
      margin-bottom: 20px;
   }

   .small-mb {
      margin-bottom: 25px;
   }

   .medium-mb {
      margin-bottom: 40px;
   }

   .smaller-ml {
      margin-left: 20px;
   }

   .medium-pb {
      padding-bottom: 40px;
   }

   .vertical-center {
      margin: auto 0;
   }

   .horizontal-center {
      margin: 0 auto;
   }

   .center-item {
      margin: auto;
   }

   .normal-link-color {
      color: #3573b1;
   }

   .link-underline {
      text-decoration: underline;
   }

   .link-underline:hover {
      text-decoration: none;
   }

   .button-normal-link {
      border: none;
      text-decoration: underline;
      background: none;
      color: #3573b1;
      padding: 0;
   }

   .button-normal-link:hover {
      text-decoration: none;
   }

   .no-bullet {
      list-style: none;
   }

   .link-no-href {
      border: none;
      background-color: transparent;
      padding: 0;
      color: #3573b1;
      margin: 0;
      font-weight: bold;
      text-decoration: underline;
   }

   .link-no-href:hover {
      cursor: pointer;
      text-decoration: none;
   }

   .usps-blue {
      color: #333366;
   }

   .picture-with-shadow {
      padding: 40px 0 0 40px;
      margin-bottom: -70px;
   }

   .flex-row {
      display: flex;
   }

   .flex-column {
      display: flex;
      flex-direction: column;
   }

   .desktop-only-flex {
      display: flex;

      @media screen and (max-width: 960px) {
         display: none;
      }
   }

   .desktop-only-block {
      display: block;

      @media screen and (max-width: 960px) {
         display: none;
      }
   }

   .mobile-only-flex {
      display: none;

      @media screen and (max-width: 960px) {
         display: flex;
      }
   }

   .mobile-only-block {
      display: none;

      @media screen and (max-width: 960px) {
         display: block;
      }
   }

   @media screen and (max-width: 1185px) {
      .mobile-reverse {
         flex-direction: column-reverse;
      }

      .picture-with-shadow {
         margin-bottom: -50px;
      }
   }
`;

export const HeroContainer = styled.div`
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   background-color: ${Theme.colors.gray[200]};

   height: 450px;
   max-height: 450px;

   img {
      width: 100%;
      max-height: 450px;
   }

   @media screen and (max-width: 1185px) {
      background-image: unset;
      height: 100%;
      max-height: 100%;
   }

   &.landing {
      background-image: url(${landingHero});
   }

   &.customer {
      background-image: url(${customerHero});
   }

   &.merchant {
      background-image: url(${merchantHero});
   }

   &.locker-lookup {
      background-image: url(${lockerLookupHero});
   }

   &.business {
      background-image: url(${businessHero});
   }
`;

export const HeroInnerContainer = styled.div`
   width: 100%;
   max-width: 1600px;
   height: 100%;
   margin: 0 auto;
`;

export const HeroContentContainer = styled.div`
   position: relative;
   z-index: 0;
   padding: 20px 40px;
   width: 100%;
   display: flex;
   height: 100%;
   flex-direction: column;
   justify-content: center;

   p {
      overflow-wrap: break-word;
      margin-bottom: 0;
      color: white;
      font-size: 16px;
   }

   .hero-width {
      max-width: 43%;
   }

   button,
   a {
      max-width: 170px;
      margin-top: 30px;
      color: white;
      overflow-wrap: break-word;
   }

   a:hover {
      text-decoration: none;
   }

   p a {
      text-decoration: underline;
   }

   &::after {
      z-index: -1;
      background: ${Theme.colors.blue};
      content: "";
      top: 10%;
      bottom: 10%;
      left: -55%;
      width: 100%;
      position: absolute;
      transform: skew(-12deg);
   }

   h1 {
      color: white;
      margin-bottom: 25px;
      font-size: 32px;
      max-width: 47%;
   }

   h2 {
      color: white;
      margin-bottom: 20px;
      max-width: 45%;
      font-size: 28px;
   }

   h3 {
      color: white;
      margin-bottom: 25px;
      max-width: 45%;
   }

   @media screen and (max-width: 1185px) {
      background: ${Theme.colors.blue};
      align-items: center;
      padding: 20px;

      p {
         max-width: 100%;
      }

      .hero-width {
         max-width: 100%;
      }

      &::after {
         background: unset;
         content: "";
         width: 0;
         height: 0;
         position: static;
      }

      h1 {
         max-width: none;
      }

      h2 {
         max-width: none;
      }

      h3 {
         max-width: 100%;
      }

      button,
      a {
         width: 100%;
         max-width: 100%;
      }
   }
`;

export const HeroSplitContent = styled.div`
   display: flex;
   justify-content: space-between;
   max-width: 500px;

   @media screen and (max-width: 960px) {
      flex-direction: column;
   }

   button,
   a {
      max-width: 170px;
      margin-top: 30px;
      min-width: 240px;
   }

   @media screen and (max-width: 960px) {
      button,
      a {
         width: 100%;
         max-width: 100%;
      }
   }
`;

export const HeroImage = styled.img`
   display: none;
   object-fit: cover;

   @media screen and (max-width: 1185px) {
      display: block;
   }
`;

export const FeatureContainer = styled.div`
   color: ${Theme.colors.black};

   h2 {
      font-size: 28px;
      margin-bottom: 25px;
   }

   ul {
      padding-left: 15px;
      color: #000;
   }

   a:link,
   a:visited,
   a:focus {
      text-decoration: underline;
      font-weight: bold;
   }

   a:hover {
      text-decoration: none;
   }

   p.content-width {
      max-width: 780px;
   }

   ul.list-width {
      max-width: 665px;
   }

   li.bottom-margin {
      margin-bottom: 5px;
   }

   &.horizontal {
      display: flex;
   }

   @media screen and (max-width: 960px) {
      h2 {
         text-align: center;
      }

      p:first-of-type {
         margin-bottom: 25px;
      }
   }

   @media screen and (max-width: 1185px) {
      &.horizontal {
         flex-direction: column;
      }
   }
`;

export const Row = styled.div`
   display: flex;
   //justify-content: space-between;
   gap: 25px;
   color: ${Theme.colors.black};
   font-size: 16px;

   > * {
      margin: 0;
      max-width: 50%;
      &:not(:last-child) {
         padding-right: 25px;
      }
   }

   @media screen and (max-width: 960px) {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0;
      line-height: 2;
      justify-content: center;
      > * {
         max-width: 100%;
         &:not(:last-child) {
            padding-right: 25px;
         }
      }
   }
`;

export const Col = styled.div`
   display: block;
`;

export const ContentContainer = styled.div`
   margin: 0 auto;
   max-width: 1600px;

   padding: 0 40px;
   // Makes content go in a row
   &.horizontal {
      display: flex;

      @media screen and (max-width: 1185px) {
         flex-direction: column;
      }
   }

   // Takes up the entire content width (useful for content that doesn't reach it)
   &.full {
      width: 100%;
      margin: 0 auto;
   }

   &.reserve-width > * {
      max-width: 530px;
   }

   @media screen and (max-width: 1185px) {
      padding: 0 20px;
   }
`;

export const PictureSideText = styled.div`
   padding-top: 55px;
   margin-right: 40px;

   @media screen and (max-width: 1185px) {
      padding: 0;
      margin-right: 0;

      h3 {
         text-align: center;
      }
   }
`;

export const NumberedListContainer = styled.div`
   margin-left: 35px;

   &.list-wrap {
      column-count: 2;
   }

   @media screen and (max-width: 960px) {
      margin: 0;

      &.list-wrap {
         column-count: 1;
      }
   }
`;

export const NumberedListBlock = styled.div`
   display: flex;
   margin-bottom: 40px;

   span {
      max-width: 417px;
      margin: 0;
   }

   .dot {
      height: 50px;
      width: 50px;
      min-width: 50px;
      min-height: 50px;
      background-color: #336;
      border-radius: 50%;
      display: inline-block;
      color: white;
      font-size: 28px;
      text-align: center;
      padding-top: 3px;
      margin: auto 27px auto 0;

      @media screen and (max-width: 1185px) {
         margin: auto 20px auto 0;
      }
   }
`;

export const GreyContentContainer = styled.div`
   margin: 0;
   width: 100%;
   background-color: #f7f7f7;

   padding: 0 40px;

   @media screen and (max-width: 1185px) {
      padding: 0 20px;
   }
`;

export const GreyContentContainerInner = styled.div`
   margin: 0 auto 40px;
   max-width: 1600px;

   padding: 40px 40px 0;

   &.horizontal {
      display: flex;
   }

   .centered {
      text-align: center;
   }

   h2 {
      margin-bottom: 40px;
   }

   @media screen and (max-width: 1185px) {
      flex-direction: column;
      padding: 40px 0 0;
      margin: 0 auto;
   }
`;

export const GreyContentCard = styled.div`
   display: flex;
   margin: 0 auto;
   max-width: 1284px;
   background-color: #f7f7f7;

   .centered {
      text-align: center;
   }

   a,
   button {
      max-width: 212px;
   }

   a {
      text-decoration: underline;
   }

   a:hover {
      text-decoration: none;
   }

   @media screen and (max-width: 1185px) {
      flex-direction: column;
      padding: 20px 0;

      button,
      a {
         width: 100%;
         max-width: 100%;
      }
   }
`;

export const CardSplit = styled.div`
   // use className="text" on component to get text side of card
   display: flex;
   width: 50%;
   max-width: 642px;

   h3 {
      margin-bottom: 25px;
   }

   ul {
      margin-bottom: 0;
      padding-left: 18px;
   }

   li {
      padding-bottom: 7px;
   }

   img {
      width: 100%;
   }

   &.text {
      flex-direction: column;
      padding: 0 40px;

      @media screen and (max-width: 959px) {
         padding: 25px 20px;
      }
   }

   &.custom-size-image {
      @media screen and (min-width: 960px) {
         height: 413px;
         width: 642px;
      }
   }

   .custom-image-holder {
      background-color: #e7e7ec;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   &.vertical-center {
      margin: auto 0;

      @media screen and (max-width: 1185px) {
         margin: 0 auto;
      }
   }

   @media screen and (max-width: 1185px) {
      width: 100%;
      margin: 0 auto;
   }
`;

export const TabSection = styled.div`
   margin-left: 40px;

   @media screen and (max-width: 1185px) {
      margin-left: auto;
   }
`;

export const FlexColumn = styled.div`
   display: flex;
   flex-direction: column;

   &.center-content {
      margin: auto;

      * {
         margin-left: auto;
         margin-right: auto;
         text-align: center;
      }
   }
`;

export const FlexRow = styled.div`
   display: flex;
`;
