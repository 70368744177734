import styled from "styled-components";
import { alphabet } from "../../common/utils";
import { Link as ScrollLink } from "react-scroll";

const SearchByStateContainer = styled.div`
   display: flex;
   flex-direction: column;

   h3 {
      text-align: center;
   }
`;

const AlphabetContainer = styled.div`
   display: flex;
   justify-content: space-between;
   width: fit-content;
   margin: 0 auto 40px;

   * {
      &:last-child {
         margin-right: 0;
      }
   }

   a:link,
   a:visited {
      margin-right: 20px;
      color: #3573b1;
      font-weight: normal;
      font-size: 16px;
   }

   .active {
      margin-right: 20px;
      color: #3573b1;
      font-weight: normal;
      font-size: 16px;
      text-decoration: underline;
   }

   .active:hover {
      text-decoration: none;
      cursor: pointer;
   }

   span {
      margin-right: 20px;
      color: #ababab;
   }

   @media screen and (max-width: 960px) {
      display: none;
   }
`;

function AlphabetSearch({ lockerList }) {
   return (
      <SearchByStateContainer>
         <h3>Search by State:</h3>
         <AlphabetContainer id="alphabet-links">
            {alphabet.map(letter => {
               let isFound = false;
               lockerList.data.forEach(state => {
                  if (state.state[0].toUpperCase() === letter) isFound = true;
               });
               if (isFound) {
                  return (
                     <ScrollLink to={letter} offset={-101}>
                        <span className="active">{letter}</span>
                     </ScrollLink>
                  );
               } else return <span>{letter}</span>;
            })}
         </AlphabetContainer>
      </SearchByStateContainer>
   );
}

export default AlphabetSearch;
