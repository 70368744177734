import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { Api } from "../../services/Api";
import {
   Container,
   FeatureContainer,
   GreyContentContainer,
   GreyContentContainerInner,
   NumberedListBlock,
   NumberedListContainer,
} from "../../common/components/WebLayoutComponents";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "../../common/components/Accordion";
import { Pagination } from "@mui/material";
import { MobileBreakpoint } from "../../config/constants";
import infoTooltip from "../../static/img/info.svg";
import Tooltip from "../../common/components/Tooltip";
import { LOCKER_LOCATION_PAGE_URL } from "../../config/pages";

const ReservationPageContainer = styled.div`
   max-width: 1276px;
   margin: 20px auto 0;
   width: 100%;
   color: black;

   .side-padding {
      padding: 0 20px;
   }

   .column-header {
      color: #333366;
      font-weight: bold;
      padding: 10px 20px;
      display: flex;
   }

   .date-column {
      flex: 1;
   }

   .shipping-column {
      flex: 2;
   }

   .pick-up-column {
      flex: 2;
   }
`;

const ReservationColumnHeaders = styled.div`
   display: flex;
   border-bottom: 1px solid #999999;
`;

const CurrentReservationContainer = styled.div`
   display: flex;
`;

const ReservationBlock = styled.div`
   display: flex;
   width: 100%;

   &.grey-background {
      background: rgb(51, 51, 102, 0.06);
   }

   div {
      padding: 30px 20px;
   }

   @media screen and (max-width: 960px) {
      flex-direction: column;

      div {
         padding: 10px 20px;
      }
   }
`;

const PastReservationContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

const PaginationContainer = styled.div`
   display: flex;
   margin: 10px auto;
   width: 100%;

   p {
      margin: auto auto auto 10px;
   }

   .reservation-count {
      margin: 10px 0 10px auto;
   }

   @media screen and (max-width: ${MobileBreakpoint}) {
      flex-direction: column;
      align-items: center;

      p {
         margin: 10px auto;
      }

      .reservation-count {
         margin: auto;
      }
   }
`;

const ShippingAddressTooltip = () => {
   return (
      <>
         <h4>Shipping Address</h4>
         <p>
            The five-digit code is your unique locker reservation number. Enter this address at the time of checkout on
            the desired merchant's site.
         </p>
      </>
   );
};

const PhysicalPostOfficeAddressTooltip = () => {
   return (
      <>
         <h4>Physical Post Office Address</h4>
         <p>
            Physical address of the Post Office where your package will be shipped to. This is the address you should
            visit to retrieve your package.
         </p>
      </>
   );
};

const CurrentAndPastReservations = () => {
   const api = new Api();
   api.setup();

   const navigate = useNavigate();

   const today = new Date();
   const month = today.getMonth() + 1;
   const day = today.getDate();
   const year = today.getFullYear();
   const date = month + "/" + day + "/" + year;

   const [pastReservations, setPastReservations] = useState();
   const [getPastReservationsError, setGetPastReservationsError] = useState(false);
   const [screenSize, setScreenSize] = useState(getCurrentDimension());
   const [mobileView, setMobileView] = useState(false);

   const reserveLockerData = JSON.parse(window.sessionStorage.getItem("selectedReservationLocker"));
   const [numberOfReservations, setNumberOfReservations] = useState();
   const [reservationsPerPage, setReservationsPerPage] = useState(3);
   const [paginationTab, setPaginationTab] = useState(1);

   const getGuestReservationsByEmail = useCallback(async () => {
      return await api.getGuestReservationsByEmail();
   }, []);

   const validLoadInfo = () => {
      if (
         sessionStorage.getItem("selectedReservationLocker") &&
         sessionStorage.getItem("reserve-locker-code") &&
         sessionStorage.getItem("guest_reserve_email")
      ) {
         return true;
      } else return false;
   };

   async function getPastGuestReservations() {
      await getGuestReservationsByEmail()
         .then(resp => {
            if (resp.ok) {
               setPastReservations(resp);
               setNumberOfReservations(resp.data.length);
            } else {
               console.log(resp);
               setGetPastReservationsError(true);
            }
         })
         .catch(err => console.log(err));
   }

   useEffect(() => {
      if (!validLoadInfo()) {
         navigate(LOCKER_LOCATION_PAGE_URL);
      }
      getPastGuestReservations();
   }, [getPastGuestReservations]);

   function getCurrentDimension() {
      return {
         width: window.innerWidth,
         height: window.innerHeight,
      };
   }

   function isMobile() {
      return screenSize.width <= 960;
   }

   useEffect(() => {
      const updateDimension = () => {
         setScreenSize(getCurrentDimension());
      };
      window.addEventListener("resize", updateDimension);

      setMobileView(isMobile());
      return () => {
         window.removeEventListener("resize", updateDimension);
      };
   }, [screenSize]);

   const handlePaginationChange = (event, number) => {
      setPaginationTab(number);
   };

   return (
      <Container>
         <ReservationPageContainer>
            <h1 className="side-padding">
               Welcome {sessionStorage.getItem("guest_reserve_email")}. Your reservation is ready!
            </h1>
            <p className="side-padding">
               You've successfully reservated a Smart Locker at the {reserveLockerData?.locationCity},{" "}
               {reserveLockerData?.locationState} Post Office location. To successfully ship a package to this locker,
               enter the shipping address listed below on the merchant's site.
            </p>
            {!mobileView && (
               <ReservationColumnHeaders>
                  <div className="date-column column-header">Reservation Confirmed</div>
                  <div className="shipping-column column-header">
                     <span>Shipping Address</span>
                     <Tooltip text={<ShippingAddressTooltip />} style={{ margin: "auto auto auto 10px" }}>
                        <img src={infoTooltip} alt="Info Button" style={{ height: "16px", width: "16px" }} />
                     </Tooltip>
                  </div>
                  <div className="column-header pick-up-column ">
                     <span>Physical Post Office Address</span>
                     <Tooltip text={<PhysicalPostOfficeAddressTooltip />} style={{ margin: "auto auto auto 10px" }}>
                        <img src={infoTooltip} alt="Info Button" style={{ height: "16px", width: "16px" }} />
                     </Tooltip>
                  </div>
               </ReservationColumnHeaders>
            )}
            <CurrentReservationContainer>
               <ReservationBlock className="grey-background">
                  {/*<strong>Reservation Placed On:</strong>*/}
                  <div className={!mobileView ? "date-column" : ""}>
                     {mobileView && (
                        <>
                           <strong style={{ color: "#343366" }}>Reservation Confirmed</strong>
                           <br />
                        </>
                     )}
                     <strong>{date}</strong>
                  </div>
                  <div className="shipping-column">
                     <strong>SHIP TO:</strong>
                     <p>
                        {reserveLockerData?.deliveryAddress +
                           " " +
                           window.sessionStorage.getItem("reserve-locker-code")}
                        <br />
                        {reserveLockerData?.deliveryAddress2 && (
                           <>
                              <>{reserveLockerData?.deliveryAddress2}</>
                              <br />
                           </>
                        )}
                        {reserveLockerData?.deliveryCity}, {reserveLockerData?.deliveryState}{" "}
                        {reserveLockerData?.deliveryZIP5 + "-" + reserveLockerData?.deliveryZIP4}
                     </p>
                  </div>
                  <div className="pick-up-column">
                     <strong>Recipient Pick Up at:</strong>
                     <p>
                        {reserveLockerData?.locationAddress}
                        <br />
                        {reserveLockerData?.locationAddress2 && (
                           <>
                              <>{reserveLockerData?.locationAddress2}</>
                              <br />
                           </>
                        )}
                        {reserveLockerData?.locationCity}, {reserveLockerData?.locationState}{" "}
                        {reserveLockerData?.locationZIP5 + "-" + reserveLockerData?.locationZIP4}
                     </p>
                  </div>
               </ReservationBlock>
            </CurrentReservationContainer>
            <p className="side-padding" style={{ marginTop: "20px" }}>
               *The shipping address listed above is a unique shipping address that is associated with your locker
               reservation. You may re-use this shipping address anytime you would like to ship to this specific Post
               Office Smart Locker location.
            </p>
            <p className="side-padding">
               If you would like to reserve a locker at a different Post Office location, please visit the{" "}
               <Link to={LOCKER_LOCATION_PAGE_URL} className="normal-link-color link-underline">
                  Locker Locator page
               </Link>
               .
            </p>
            <Accordion
               style={{ marginTop: "40px" }}
               title="Past Reservations"
               titleType="h1"
               titleStyle={{ padding: "0 20px" }}
            >
               <p className="side-padding">Below is the list of your past reservations.</p>
               <PaginationContainer>
                  <Pagination
                     count={Math.ceil(numberOfReservations / reservationsPerPage)}
                     page={paginationTab}
                     onChange={handlePaginationChange}
                     style={{ margin: "auto 0" }}
                  />
                  <p>
                     Showing Results{" "}
                     {(paginationTab - 1) * reservationsPerPage +
                        1 +
                        " - " +
                        (numberOfReservations > paginationTab * reservationsPerPage
                           ? paginationTab * reservationsPerPage
                           : numberOfReservations)}{" "}
                     of {numberOfReservations}
                  </p>
                  <div className="reservation-count">
                     <span>Reservations per Page: </span>
                     <select
                        name="reservations-per-page-select"
                        id="reservations-per-page-select"
                        defaultValue={reservationsPerPage}
                        onChange={e => setReservationsPerPage(e.target.value)}
                     >
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                     </select>
                  </div>
               </PaginationContainer>
               {pastReservations && !mobileView && (
                  <ReservationColumnHeaders>
                     <div className="date-column column-header">Reservation Confirmed</div>
                     <div className="shipping-column column-header">
                        <span>Shipping Address</span>
                        <Tooltip text={<ShippingAddressTooltip />} style={{ margin: "auto auto auto 10px" }}>
                           <img src={infoTooltip} alt="Info Button" style={{ height: "16px", width: "16px" }} />
                        </Tooltip>
                     </div>
                     <div className="column-header pick-up-column ">
                        <span>Physical Post Office Address</span>
                        <Tooltip text={<PhysicalPostOfficeAddressTooltip />} style={{ margin: "auto auto auto 10px" }}>
                           <img src={infoTooltip} alt="Info Button" style={{ height: "16px", width: "16px" }} />
                        </Tooltip>
                     </div>
                  </ReservationColumnHeaders>
               )}
               <PastReservationContainer>
                  {pastReservations?.data.map((pastReservation, index) => {
                     const classNameValue = index % 2 === 1 ? "grey-background" : undefined;
                     const dateOfReservation = pastReservation["date-of-reservation"].split(" ")[0];

                     if (
                        index <= paginationTab * reservationsPerPage - 1 &&
                        index >= (paginationTab - 1) * reservationsPerPage
                     ) {
                        return (
                           <ReservationBlock className={classNameValue} key={index}>
                              <div className="date-column">
                                 {mobileView && (
                                    <>
                                       <strong style={{ color: "#343366" }}>Reservation Confirmed</strong>
                                       <br />
                                    </>
                                 )}
                                 <strong>{dateOfReservation}</strong>
                              </div>
                              <div className="shipping-column">
                                 <strong>SHIP TO:</strong>
                                 <p>
                                    {pastReservation["delivery-address1"] +
                                       " " +
                                       window.sessionStorage.getItem("reserve-locker-code")}
                                    <br />
                                    {pastReservation["delivery-address2"] && (
                                       <>
                                          <>{pastReservation["delivery-address2"]}</>
                                          <br />
                                       </>
                                    )}
                                    {pastReservation["delivery-city"]}, {pastReservation["delivery-state"]}{" "}
                                    {pastReservation["delivery-zip5"] + "-" + pastReservation["delivery-zip4"]}
                                 </p>
                                 <p></p>
                              </div>
                              <div className="pick-up-column">
                                 <strong>Recipient Pick Up at:</strong>
                                 <p>
                                    {pastReservation["location-address1"]}
                                    <br />
                                    {pastReservation["location-address2"] && (
                                       <>
                                          <>{pastReservation["location-address2"]}</>
                                          <br />
                                       </>
                                    )}
                                    {pastReservation["location-city"]}, {pastReservation["location-state"]}{" "}
                                    {pastReservation["location-zip5"] + "-" + pastReservation["location-zip4"]}
                                 </p>
                              </div>
                           </ReservationBlock>
                        );
                     }
                  })}
                  {!pastReservations && !getPastReservationsError && (
                     <h1 style={{ textAlign: "center" }}>You do not have any past reservations.</h1>
                  )}
                  {!pastReservations && getPastReservationsError && (
                     <h1 style={{ textAlign: "center" }}>There was a problem fetching past reservations.</h1>
                  )}
               </PastReservationContainer>
            </Accordion>
         </ReservationPageContainer>
         <GreyContentContainer>
            <GreyContentContainerInner className="horizontal" style={{ maxWidth: "1276px" }}>
               <FeatureContainer className="horizontal-center">
                  <h2 className="small-mb" style={{ marginBottom: "25px" }}>
                     How Smart Locker Reservations Work:
                  </h2>
                  <p>
                     If a desired merchant does not provide the option to ship direct to a Smart Locker, you can use the
                     Smart Locker Reservation feature to reserve and ship packages to a designated Smart Locker.
                  </p>
                  <h3 className="medium-mb">
                     Now that you have requested a locker reservation, please complete the following steps to ship your
                     package to your designated locker.
                  </h3>
                  <NumberedListContainer className="list-wrap">
                     <NumberedListBlock>
                        <span className="dot">1</span>
                        <span>
                           <strong>Review your Reservation Information:</strong> Review the reservation details in the
                           table above. You also should have received an email confirming your locker reservation.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">2</span>
                        <span>
                           <strong>Enter Shipping Address at Checkout:</strong> Return to the merchant's website and
                           proceed to checkout. Instead of using your usual shipping address, enter the unique shipping
                           address provided in the reservation confirmation email.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">3</span>
                        <span>
                           <strong>Complete Purchase:</strong> Finalize your purchase on the merchant's website as
                           usual, ensuring that the correct shipping address (the Smart Locker address) is selected.
                           This address can be under the “Shipping Address” column.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">4</span>
                        <span>
                           <strong>Package has been Delivered:</strong> You will receive an email notification once the
                           package has been successfully delivered. The email will include a one-time QR code that will
                           be used to retrieve your package.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">5</span>
                        <span>
                           <strong>Visit Smart Locker Location:</strong> Go to the Post Office Smart Locker location
                           where you reserved the locker. This address can be found under the “Physical Post Office
                           Location” column.
                        </span>
                     </NumberedListBlock>
                     <NumberedListBlock>
                        <span className="dot">6</span>
                        <span>
                           <strong>Retrieve Package:</strong> Scan the QR code you received in the email to open the
                           locker compartment and retrieve your package.
                        </span>
                     </NumberedListBlock>
                  </NumberedListContainer>
               </FeatureContainer>
            </GreyContentContainerInner>
         </GreyContentContainer>
      </Container>
   );
};

export default CurrentAndPastReservations;
