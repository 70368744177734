import styled from "styled-components";
import { Button, CaretIcon, CloseIcon, Theme } from "@informed-innovations/components";
import {
   BUSINESS_PAGE_URL,
   BUSINESS_TAB_NAME,
   CUSTOMER_PAGE_URL,
   CUSTOMER_TAB_NAME,
   FAQ_PAGE_URL,
   LOCKER_LOCATION_PAGE_URL,
   MERCHANT_PAGE_URL,
   MERCHANT_TAB_NAME,
} from "../../config/pages";
import { HamburgerIcon } from "./icons/HamburgerIcon";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Api } from "../../services/Api";
import { MobileBreakpoint } from "../../config/constants";

const HeaderContainer = styled.div`
   margin-bottom: -40px;

   @media only screen and (max-width: 959px) {
      margin-bottom: -20px;
   }

   html,
   body {
      min-width: 0 !important;
   }

   a {
      text-decoration: none;
   }

   /* */
   @media (min-width: 958px) {
      .global--navigation ~ .g-alert,
      .g-alert ~ .g-alert,
      .g-alert {
         margin-bottom: 20px;
         margin-top: 0;
      }

      #utility-header {
         height: 30px;
      }

      .hidden-galert {
         position: absolute;
         left: -10000px;
         top: auto;
         width: 1px;
         height: 1px;
         overflow: hidden;
      }

      div#g-navigation {
         margin-bottom: 40px;
      }
   }
   @media (max-width: 958px) {
      .g-alert p br {
         display: none;
      }

      .global--navigation .mobile-header {
         margin-bottom: 20px;
      }
   }

   .mobile-header {
      display: flex;
   }

   .mobile-help {
      font-size: 16px;
      color: #333366;
      position: absolute;
      top: 20px;
      left: 5%;
      min-width: 150px;
   }

   .mobile-help:before {
      content: "";
      display: inline-block;
      height: 10px;
      width: 10px;
      background-image: url(https://www.usps.com/assets/images/home/text-link-carat-red.svg);
      background-repeat: no-repeat;
      margin-left: 0px;
      top: 0px;
      position: relative;
      left: -5px;
      -webkit-transform: rotate(180deg);
      -moz-transition: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
   }

   /* Respacing for changes to megamenu */
   @media only screen and (min-width: 958px) {
      .global--navigation nav li.menuheader:nth-of-type(1) {
         width: 15%;
      }

      .global--navigation nav li.menuheader:nth-of-type(2) {
         width: 11%;
      }

      .global--navigation nav li.menuheader:nth-of-type(3) {
         width: 11% !important;
      }

      .global--navigation nav li.menuheader:nth-of-type(4) {
         width: 13%;
      }

      .global--navigation nav li.menuheader:nth-of-type(5) {
         width: 15%;
      }

      .global--navigation nav li.menuheader:nth-of-type(6) {
         width: 16%;
      }

      .global--navigation nav li.menuheader:nth-of-type(7) {
         width: 12%;
      }

      .global--navigation nav li.menuheader:nth-of-type(8) {
         width: 4%;
      }
   }
   @media (min-width: ${MobileBreakpoint}) and (max-width: 1100px) {
      .global--navigation nav li.menuheader:nth-of-type(1) {
         width: 15%;
      }

      .global--navigation nav li.menuheader:nth-of-type(2) {
         width: 12%;
      }

      .global--navigation nav li.menuheader:nth-of-type(3) {
         width: 12%;
      }

      .global--navigation nav li.menuheader:nth-of-type(4) {
         width: 12%;
      }

      .global--navigation nav li.menuheader:nth-of-type(5) {
         width: 12%;
      }

      .global--navigation nav li.menuheader:nth-of-type(6) {
         width: 15%;
      }

      .global--navigation nav li.menuheader:nth-of-type(7) {
         width: 10%;
      }

      .global--navigation nav li.menuheader:nth-of-type(8) {
         width: 3%;
      }
   }
   /* end respacing for megamenu*/

   /* adding the Print Customs Forms image */
   @media only screen and (min-width: ${MobileBreakpoint}) {
      .global--navigation nav .tool-international-forms a:before,
      .global--navigation nav .tool-international-forms a:hover:before,
      .global--navigation nav .tool-international-forms a:focus:before {
         background: url(https://www.usps.com/assets/images/home/printcustomsforms.svg);
      }
   }

   /* end adding the Print Customs Forms image */

   /* SMARTLOCKER HEADER */

   // Custom smart locker styles
   .curtain {
      background: ${Theme.colors.gray[200]};
      position: fixed;
      top: 53px;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 150;
   }

   .curtain-header {
      display: flex;
      height: 53px;
      background: ${Theme.colors.blue};
      justify-content: center;
      align-items: center;
      border-top: 2px solid ${Theme.colors.red};

      span {
         font-weight: bold;
         color: white;
         font-size: 18px;
         text-align: center;
      }
   }

   .curtain-links {
      display: flex;
      flex-direction: column;
   }

   .curtain-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 70px;
      color: black;
      font-weight: bold;
      font-size: 16px;
      text-decoration: none;
      background: ${Theme.colors.gray[200]};
      border-bottom: 1px solid ${Theme.colors.blue};
      padding: 0 10px;
      box-sizing: border-box;
      &:hover {
         background: ${Theme.colors.gray[400]};
      }
      svg {
         transform: rotate(-90deg);
      }
      span {
         color: ${Theme.colors.blue};
      }
   }
`;

// Custom styles
const Curtain = styled.div``;
const CurtainHeader = styled.div``;
const CurtainLinks = styled.div``;
const CurtainLink = styled(Link)``;

const HamburgerButton = styled(Button)`
   display: block;
   position: absolute;
   top: 2px;
   left: 10%;
   padding: 0;
   min-width: 0;
   align-items: center;
   flex: 1 0 25px;
   &:focus {
      outline: none;
   }
   &:focus-visible {
      outline: 3px solid #52addb;
   }
   svg {
      width: 25px;
      height: 25px;
   }
`;

export const USPSGlobalHeader = props => {
   const [curtainOpen, setCurtainOpen] = useState(false);
   const location = useLocation();

   const api = new Api();
   api.setup();

   const logAnalytics = async utm_campaign => {
      const pathName = window.location.pathname.substring(1);
      const utm_source = pathName === "" ? "lockers-main" : pathName;
      const utm_show_id = Number(localStorage.getItem("show_id"));
      return await api.postDynamicLogAnalytics(utm_source, "click", utm_campaign, utm_show_id);
   };

   useEffect(() => {
      setCurtainOpen(false);
   }, [location]);

   return (
      <HeaderContainer>
         <link
            href="https://www.usps.com/global-elements/header/css/megamenu-c360.css"
            type="text/css"
            rel="stylesheet"
         />
         <div className="nav-utility" id="nav-utility">
            <div className="utility-links" id="utility-header">
               <a tabIndex={-1} href="https://www.usps.com/globals/site-index.htm" className="hidden-skip">
                  Go to USPS.com Site Index.
               </a>
               <a tabIndex={0} id="skiptomain" href="#endnav" className="hidden-skip keyboard">
                  Skip to Main Content
               </a>
               <a tabIndex={-1} name="skiputil" id="skiputil" href="#skipallnav" className="hidden-skip">
                  Skip All Utility Navigation
               </a>
               <a id="link-locator" href="https://tools.usps.com/locations/">
                  Locations
               </a>
               <a id="link-customer" href="https://www.usps.com/help/contact-us.htm">
                  Support
               </a>
               <a id="link-myusps" href="https://informeddelivery.usps.com">
                  Informed Delivery
               </a>
            </div>
         </div>
         <div className="global--navigation" id="g-navigation">
            <a tabIndex={0} name="skipallnav" id="skipallnav" href="#endnav" className="hidden-skip keyboard">
               Skip to Main Content
            </a>
            <div className="nav-full">
               <a className="global-logo" href="https://www.usps.com/">
                  <img
                     src="https://www.usps.com/global-elements/header/images/utility-header/logo-sb.svg"
                     alt="Image of USPS.com logo."
                     aria-label="Image of USPS.com logo."
                  />
               </a>
               <div className="mobile-header">
                  <a className="mobile-help" style={{ display: "none" }} href="https://faq.usps.com/s/">
                     USPS.com Help
                  </a>
                  <HamburgerButton text title="Menu" onClick={() => setCurtainOpen(!curtainOpen)}>
                     {curtainOpen ? <CloseIcon /> : <HamburgerIcon />}
                  </HamburgerButton>
                  <a
                     className="mobile-logo"
                     href="https://www.usps.com/"
                     style={{ width: "fit-content", margin: "0 auto" }}
                  >
                     <img
                        src="https://www.usps.com/assets/images/home/logo_mobile.svg"
                        alt="USPS mobile logo"
                        style={{ margin: "0" }}
                     />
                  </a>
                  <a className="mobile-search" href="https://www.usps.com/search/results.htm?keyword=Contact%20Us">
                     <img src="https://www.usps.com/assets/images/home/search.svg" alt="Search Icon" />
                  </a>
               </div>
               {curtainOpen && (
                  <Curtain className="curtain">
                     <CurtainHeader className="curtain-header">
                        <span>USPS Smart Locker</span>
                     </CurtainHeader>
                     <CurtainLinks className="curtain-links">
                        <CurtainLink className="curtain-link" to="/" onClick={() => logAnalytics("lockers-main")}>
                           <span>Smart Lockers Home Page</span>
                           <CaretIcon />
                        </CurtainLink>
                        <CurtainLink
                           className="curtain-link"
                           to={CUSTOMER_PAGE_URL}
                           onClick={() => logAnalytics(CUSTOMER_PAGE_URL)}
                        >
                           <span>{CUSTOMER_TAB_NAME}</span>
                           <CaretIcon />
                        </CurtainLink>
                        {props.psFlag === "true" ? (
                           <></>
                        ) : (
                           <CurtainLink
                              className="curtain-link"
                              to={MERCHANT_PAGE_URL}
                              onClick={() => logAnalytics(MERCHANT_PAGE_URL)}
                           >
                              <span>{MERCHANT_TAB_NAME}</span>
                              <CaretIcon />
                           </CurtainLink>
                        )}
                        <CurtainLink
                           className="curtain-link"
                           to={BUSINESS_PAGE_URL}
                           onClick={() => logAnalytics("business")}
                        >
                           <span>{BUSINESS_TAB_NAME}</span>
                           <CaretIcon />
                        </CurtainLink>
                        <CurtainLink
                           className="curtain-link"
                           to={LOCKER_LOCATION_PAGE_URL}
                           onClick={() => logAnalytics("locker-location")}
                        >
                           <span>Locker Locations</span>
                           <CaretIcon />
                        </CurtainLink>
                        <CurtainLink className="curtain-link" to={FAQ_PAGE_URL} onClick={() => logAnalytics("faq")}>
                           <span>FAQs</span>
                           <CaretIcon />
                        </CurtainLink>
                        {/*{props?.tradeShow && props?.tradeShow.length !== 0 && props?.tradeShow[0]["contest-flag"] && (*/}
                        {/*   <CurtainLink className="curtain-link" to="/npf2024" onClick={() => logAnalytics("npf2024")}>*/}
                        {/*      <span>{props.tradeShow[0]["show-name"]}</span>*/}
                        {/*      <CaretIcon />*/}
                        {/*   </CurtainLink>*/}
                        {/*)}*/}
                     </CurtainLinks>
                  </Curtain>
               )}
               <nav>
                  <div className="mobile-log-state">
                     <div id="msign" className="mobile-utility">
                        <div className="mobile-sign">
                           <a href="https://reg.usps.com/entreg/LoginAction_input?app=Phoenix&appURL=">Sign In</a>
                        </div>
                     </div>
                  </div>
                  <ul className="nav-list" role="menubar">
                     <li className="qt-nav menuheader">
                        <a
                           tabIndex={-1}
                           name="navquicktools"
                           id="navquicktools"
                           href="#navmailship"
                           className="hidden-skip"
                        >
                           Skip Quick Tools Links
                        </a>
                        <a
                           aria-expanded="false"
                           role="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           className="nav-first-element menuitem"
                           href="#"
                        >
                           Quick Tools
                        </a>
                        <div className>
                           <ul role="menu" aria-hidden="true">
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://tools.usps.com/go/TrackConfirmAction_input"
                                 >
                                    <img
                                       src="https://www.usps.com/assets/images/home/tracking.svg"
                                       alt="Tracking Icon"
                                    />
                                    <p>Track a Package</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://informeddelivery.usps.com/">
                                    <img
                                       src="https://www.usps.com/global-elements/header/images/utility-header/mailman.svg"
                                       alt="Informed Delivery Icon"
                                    />
                                    <p>Informed Delivery</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://tools.usps.com/locations/">
                                    <img
                                       src="https://www.usps.com/assets/images/home/location.svg"
                                       alt="Post Office Locator Icon"
                                    />
                                    <p>Find USPS Locations</p>
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://store.usps.com/store/browse/category.jsp?categoryId=buy-stamps"
                                 >
                                    <img src="https://www.usps.com/assets/images/home/stamps.svg" alt="Stamps Icon" />
                                    <p>Buy Stamps</p>
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://tools.usps.com/schedule-pickup-steps.htm"
                                 >
                                    <img
                                       src="https://www.usps.com/assets/images/home/schedule_pickup.svg"
                                       alt="Schedule a Pickup Icon"
                                    />
                                    <p>Schedule a Pickup</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://postcalc.usps.com/">
                                    <img
                                       src="https://www.usps.com/assets/images/home/calculate_price.svg"
                                       alt="Calculate a Price Icon"
                                    />
                                    <p>Calculate a Price</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://tools.usps.com/zip-code-lookup.htm">
                                    <img
                                       src="https://www.usps.com/assets/images/home/find_zip.svg"
                                       alt="Zip Code™ Lookup Icon"
                                    />
                                    <p>
                                       Look Up a <br />
                                       ZIP Code<sup>™</sup>
                                    </p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://holdmail.usps.com/holdmail/">
                                    <img
                                       src="https://www.usps.com/assets/images/home/holdmail.svg"
                                       alt="Holdmail Icon"
                                    />
                                    <p>Hold Mail</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://moversguide.usps.com/?referral=MG82">
                                    <img
                                       src="https://www.usps.com/assets/images/home/change_address.svg"
                                       alt="Change of Address Icon"
                                    />
                                    <p>Change My Address</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/po-boxes.htm">
                                    <img
                                       src="https://www.usps.com/assets/images/home/po_box.svg"
                                       alt="Post Office Boxes Icon"
                                    />
                                    <p>
                                       Rent/Renew a <br />
                                       PO Box
                                    </p>
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://store.usps.com/store/results/free-shipping-supplies/shipping-supplies/_/N-alnx4jZ7d0v8v"
                                 >
                                    <img
                                       src="https://www.usps.com/assets/images/home/free_boxes.svg"
                                       alt="Shipping Supplies Icon"
                                    />
                                    <p>Free Boxes</p>
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://cns.usps.com/">
                                    <img
                                       src="https://www.usps.com/assets/images/home/featured_clicknship.svg"
                                       alt="Click-N-Ship Icon"
                                    />
                                    <p>Click-N-Ship</p>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                     <li className="menuheader">
                        <a
                           tabIndex={-1}
                           name="navmailship"
                           id="navmailship"
                           href="#navtrackmanage"
                           className="hidden-skip"
                        >
                           Skip Send Links
                        </a>
                        <a
                           id="mail-ship-width"
                           aria-expanded="false"
                           className="menuitem"
                           role="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           href="https://www.usps.com/ship/"
                        >
                           Send
                        </a>
                        <div className="repos">
                           <ul role="menu" aria-hidden="true" className="tools">
                              <h3>Tools</h3>
                              <li className="tool-cns">
                                 <a role="menuitem" tabIndex={-1} href="https://cns.usps.com/">
                                    Click-N-Ship
                                 </a>
                              </li>
                              <li className="tool-stamps">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/">
                                    Stamps &amp; Supplies
                                 </a>
                              </li>
                              <li className="tool-zip">
                                 <a role="menuitem" tabIndex={-1} href="https://tools.usps.com/zip-code-lookup.htm">
                                    Look Up a ZIP Code<sup>™</sup>
                                 </a>
                              </li>
                              <li className="tool-calc">
                                 <a role="menuitem" tabIndex={-1} href="https://postcalc.usps.com/">
                                    Calculate a Price
                                 </a>
                              </li>
                              <li className="tool-pick">
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://tools.usps.com/schedule-pickup-steps.htm"
                                 >
                                    Schedule a Pickup
                                 </a>
                              </li>
                              <li className="tool-find">
                                 <a role="menuitem" tabIndex={-1} href="https://tools.usps.com/locations/">
                                    Find USPS Locations
                                 </a>
                              </li>
                              <li className="tool-track">
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://tools.usps.com/go/TrackConfirmAction_input"
                                 >
                                    Tracking
                                 </a>
                              </li>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3>Learn About</h3>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/">
                                    Sending
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/letters.htm">
                                       Sending Mail
                                    </a>
                                 </li>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/packages.htm">
                                       Sending Packages
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/ship/insurance-extra-services.htm"
                                    >
                                       Insurance &amp; Extra Services
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/ship/shipping-restrictions.htm"
                                    >
                                       Shipping Restrictions
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/online-shipping.htm">
                                    Online Shipping
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="/package-shippers">
                                       Ship to USPS Smart Locker
                                    </a>
                                 </li>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/label-broker.htm">
                                       Label Broker
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/custom-mail.htm">
                                    Custom Mail, Cards, &amp; Envelopes
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/prices.htm">
                                    Postage Prices
                                 </a>
                              </li>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3 className="desktop-only">&nbsp;</h3>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/ship/mail-shipping-services.htm"
                                 >
                                    Mail &amp; Shipping Services
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/ship/priority-mail-express.htm"
                                    >
                                       Priority Mail Express
                                    </a>
                                 </li>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/priority-mail.htm">
                                       Priority Mail
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/ship/ground-advantage.htm"
                                    >
                                       USPS Ground Advantage
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/ship/first-class-mail.htm"
                                    >
                                       First-Class Mail
                                    </a>
                                 </li>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/apo-fpo-dpo.htm">
                                       Military &amp; Diplomatic Mail
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/manage/package-intercept.htm"
                                 >
                                    Redirecting a Package
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/shop/money-orders.htm">
                                    Money Orders
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/help/claims.htm">
                                    Filing a Claim
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/help/refunds.htm">
                                    Requesting a Refund
                                 </a>
                              </li>
                              <div className="desktop-only mailship-addition">
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/ship/go-now.htm">
                                    <img
                                       src="https://www.usps.com/ship/go-now.png"
                                       alt="Mail and Ship image with call to action."
                                    />
                                 </a>
                              </div>
                           </ul>
                        </div>
                     </li>
                     <li className="menuheader">
                        <a
                           tabIndex={-1}
                           name="navtrackmanage"
                           id="navtrackmanage"
                           href="#navpostalstore"
                           className="hidden-skip"
                        >
                           Skip Receive Links
                        </a>
                        <a
                           aria-expanded="false"
                           className="menuitem"
                           role="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           href="https://www.usps.com/manage/"
                        >
                           Receive
                        </a>
                        <div>
                           <ul role="menu" aria-hidden="true" className="tools">
                              <h3>Tools</h3>
                              <li className="tool-track">
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://tools.usps.com/go/TrackConfirmAction_input"
                                 >
                                    Tracking
                                 </a>
                              </li>
                              <li className="tool-informed">
                                 <a role="menuitem" tabIndex={-1} href="https://informeddelivery.usps.com">
                                    Informed Delivery
                                 </a>
                              </li>
                              <li className="tool-intercept">
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://retail-pi.usps.com/retailpi/actions/index.action"
                                 >
                                    Intercept a Package
                                 </a>
                              </li>
                              <li className="tool-redelivery">
                                 <a role="menuitem" tabIndex={-1} href="https://tools.usps.com/redelivery.htm">
                                    Schedule a Redelivery
                                 </a>
                              </li>
                              <li className="tool-hold">
                                 <a role="menuitem" tabIndex={-1} href="https://holdmail.usps.com/holdmail/">
                                    Hold Mail
                                 </a>
                              </li>
                              <li className="tool-change">
                                 <a role="menuitem" tabIndex={-1} href="https://moversguide.usps.com/?referral=MG80">
                                    Change of Address
                                 </a>
                              </li>
                              <li className="tool-pobol">
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/po-boxes.htm">
                                    Rent or Renew PO Box
                                 </a>
                              </li>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3>Learn About</h3>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/">
                                    Managing Mail
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://informeddelivery.usps.com/">
                                    Informed Delivery
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/forward.htm">
                                    Forwarding Mail
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/manage/package-intercept.htm"
                                 >
                                    Redirecting a Package
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="/package-recipients">
                                    USPS Smart Lockers
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/po-boxes.htm">
                                    PO Boxes
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/mailboxes.htm">
                                    Mailbox Guidelines
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/manage/mail-for-deceased.htm"
                                 >
                                    Mail for the Deceased
                                 </a>
                              </li>
                              <div className="desktop-only manage-addition">
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/manage/go-now.htm">
                                    <img
                                       src="https://www.usps.com/manage/go-now.png"
                                       alt="Manage image with call to action."
                                    />
                                 </a>
                              </div>
                           </ul>
                        </div>
                     </li>
                     <li className="menuheader">
                        <a
                           tabIndex={-1}
                           name="navpostalstore"
                           id="navpostalstore"
                           href="#navbusiness"
                           className="hidden-skip"
                        >
                           Skip Shop Links
                        </a>
                        <a
                           aria-expanded="false"
                           className="menuitem"
                           role="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           href="https://store.usps.com/store"
                        >
                           Shop
                        </a>
                        <div className="repos">
                           <ul role="menu" aria-hidden="true" className="tools">
                              <h3>Shop</h3>
                              <li className="tool-stamps">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/stamps">
                                    Stamps
                                 </a>
                              </li>
                              <li className="tool-supplies">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/shipping-supplies">
                                    Shipping Supplies
                                 </a>
                              </li>
                              <li className="tool-cards">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/cards-envelopes">
                                    Cards &amp; Envelopes
                                 </a>
                              </li>
                              <li className="tool-pse">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/pse/">
                                    Personalized Stamped Envelopes
                                 </a>
                              </li>
                              <li className="tool-coll">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/collectors">
                                    Collectors
                                 </a>
                              </li>
                              <li className="tool-gifts">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/gifts">
                                    Gifts
                                 </a>
                              </li>
                              <li className="tool-business">
                                 <a role="menuitem" tabIndex={-1} href="https://store.usps.com/store/business-supplies">
                                    Business Supplies
                                 </a>
                              </li>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3>Learn About</h3>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/shop/money-orders.htm">
                                    Money Orders
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/shop/returns-exchanges.htm"
                                 >
                                    Returns &amp; Exchanges
                                 </a>
                              </li>
                              <div className="desktop-only shop-addition">
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/store/go-now.htm">
                                    <img
                                       src="https://www.usps.com/store/go-now.png"
                                       alt="Store image with call to action."
                                    />
                                 </a>
                              </div>
                           </ul>
                        </div>
                     </li>
                     <li className="menuheader">
                        <a
                           tabIndex={-1}
                           name="navbusiness"
                           id="navbusiness"
                           href="#navinternational"
                           className="hidden-skip"
                        >
                           Skip Business Links
                        </a>
                        <a
                           aria-expanded="false"
                           className="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           role="menuitem"
                           href="https://www.usps.com/business/"
                        >
                           Business
                        </a>
                        <div className="repos">
                           <ul role="menu" aria-hidden="true" className="tools">
                              <h3>Tools</h3>
                              <li className="tool-calc">
                                 <a role="menuitem" tabIndex={-1} href="https://postcalc.usps.com/business">
                                    Calculate a Business Price
                                 </a>
                              </li>
                              <li className="tool-eddm">
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://eddm.usps.com/eddm/customer/routeSearch.action"
                                 >
                                    Every Door Direct Mail
                                 </a>
                              </li>
                              <div className="desktop-only business-addition">
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/go-now.htm">
                                    <img
                                       src="https://www.usps.com/business/go-now.png"
                                       alt="Business image with call to action."
                                    />
                                 </a>
                              </div>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3>Learn About</h3>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/business/business-shipping.htm"
                                 >
                                    Business Shipping
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/loyalty.htm">
                                       USPS Loyalty Program
                                    </a>
                                 </li>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="/business">
                                       Ship to USPS Smart Locker
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/shipping-consolidators.htm"
                                    >
                                       Shipping Consolidators
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/business/advertise-with-mail.htm"
                                 >
                                    Advertising with Mail
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/every-door-direct-mail.htm"
                                    >
                                       Using EDDM
                                    </a>
                                 </li>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/vendors.htm">
                                       Mailing &amp; Printing Services
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/customized-direct-mail.htm"
                                    >
                                       Customized Direct Mail
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/political-mail.htm"
                                    >
                                       Political Mail
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/promotions-incentives.htm"
                                    >
                                       Promotions &amp; Incentives
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/informed-delivery.htm"
                                    >
                                       Informed Delivery Marketing
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/product-samples.htm"
                                    >
                                       Product Samples
                                    </a>
                                 </li>
                              </ul>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3 className="desktop-only">&nbsp;</h3>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/connect/">
                                    USPS Connect
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/connect/ecommerce.htm"
                                    >
                                       eCommerce
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/connect/local.htm"
                                    >
                                       Local
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/business/postage-options.htm"
                                 >
                                    Postage Options
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/business/verify-postage.htm"
                                    >
                                       Verifying Postage
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/business/return-services.htm"
                                 >
                                    Returns Services
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/label-broker.htm">
                                    Label Broker for Business
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/business/international-shipping.htm"
                                 >
                                    International Business Shipping
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/manage-mail.htm">
                                    Managing Business Mail
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/web-tools-apis/">
                                    USPS & Web tools APIs
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/business/prices.htm">
                                    Prices
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                     <li className="menuheader">
                        <a
                           tabIndex={-1}
                           name="navinternational"
                           id="navinternational"
                           href="#navhelp"
                           className="hidden-skip"
                        >
                           Skip International Links
                        </a>
                        <a
                           className="menuitem"
                           tabIndex={0}
                           aria-expanded="false"
                           aria-haspopup="true"
                           role="menuitem"
                           href="https://www.usps.com/international/"
                        >
                           International
                        </a>
                        <div className="repos">
                           <ul role="menu" aria-hidden="true" className="tools">
                              <h3>Tools</h3>
                              <li className="tool-calc">
                                 <a role="menuitem" tabIndex={-1} href="https://postcalc.usps.com/?country=10440">
                                    Calculate International Prices
                                 </a>
                              </li>
                              <li className="tool-international-labels">
                                 <a role="menuitem" tabIndex={-1} href="https://cns.usps.com/">
                                    Print International Labels
                                 </a>
                              </li>
                              <li className="tool-international-forms">
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://cfo.usps.com/cfo-web/labelInformation.html"
                                 >
                                    Print Customs Forms
                                 </a>
                              </li>
                              <div className="desktop-only international-addition">
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/international/go-now.htm">
                                    <img
                                       src="https://www.usps.com/international/go-now.png"
                                       alt="International image with call to action"
                                    />
                                 </a>
                              </div>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3>Learn About</h3>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/international/">
                                    International Sending
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/letters.htm"
                                    >
                                       How to Send a Letter Internationally
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/preparing-international-shipments.htm"
                                    >
                                       How to Send a Package Internationally
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/shipping-restrictions.htm"
                                    >
                                       International Shipping Restrictions
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/international-how-to.htm"
                                    >
                                       Shipping Internationally Online
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/insurance-extra-services.htm"
                                    >
                                       International Insurance &amp; Extra Services
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/customs-forms.htm"
                                    >
                                       Completing Customs Forms
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/ship/apo-fpo-dpo.htm?pov=international"
                                 >
                                    Military &amp; Diplomatic Mail
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/international/money-transfers.htm"
                                 >
                                    Sending Money Abroad
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/international/passports.htm"
                                 >
                                    Passports
                                 </a>
                              </li>
                           </ul>
                           <ul role="menu" aria-hidden="true">
                              <h3 className="desktop-only">&nbsp;</h3>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/international/mail-shipping-services.htm"
                                 >
                                    Comparing International Shipping Services
                                 </a>
                              </li>
                              <ul>
                                 <li>
                                    <a role="menuitem" tabIndex={-1} href="https://www.usps.com/international/gxg.htm">
                                       Global Express Guaranteed
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/priority-mail-express-international.htm"
                                    >
                                       Priority Mail Express International
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/priority-mail-international.htm"
                                    >
                                       Priority Mail International
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/first-class-package-international-service.htm"
                                    >
                                       First-Class Package International Service
                                    </a>
                                 </li>
                                 <li>
                                    <a
                                       role="menuitem"
                                       tabIndex={-1}
                                       href="https://www.usps.com/international/first-class-mail-international.htm"
                                    >
                                       First-Class Mail International
                                    </a>
                                 </li>
                              </ul>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/help/international-claims.htm"
                                 >
                                    Filing an International Claim
                                 </a>
                              </li>
                              <li>
                                 <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    href="https://www.usps.com/help/international-refunds.htm"
                                 >
                                    Requesting an International Refund
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                     <li className="menuheader">
                        <a tabIndex={-1} name="navhelp" id="navhelp" href="#navsearch" className="hidden-skip">
                           Skip Help Links
                        </a>
                        <a
                           aria-expanded="false"
                           className="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           role="menuitem"
                           href="https://faq.usps.com/s/"
                        >
                           Help
                        </a>
                        <div className="repos">
                           <ul role="menu" aria-hidden="true">
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://faq.usps.com/s/">
                                    FAQs
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/help/missing-mail.htm">
                                    Finding Missing Mail
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/help/claims.htm">
                                    Filing a Claim
                                 </a>
                              </li>
                              <li>
                                 <a role="menuitem" tabIndex={-1} href="https://www.usps.com/help/refunds.htm">
                                    Requesting a Refund
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                     <li className="nav-search menuheader">
                        <a tabIndex={-1} name="navsearch" id="navsearch" href="#endnav" className="hidden-skip">
                           Skip Search
                        </a>
                        <a
                           aria-expanded="false"
                           className="menuitem"
                           tabIndex={0}
                           aria-haspopup="true"
                           role="menuitem"
                           href="https://www.usps.com/search/results.htm?keyword=Contact%20Us"
                        >
                           Search USPS.com
                        </a>
                     </li>
                     {/* END Search */}
                  </ul>
               </nav>
            </div>
            <a name="endnav" id="endnav" href="#" className="hidden-skip">
               &nbsp;
            </a>
         </div>
      </HeaderContainer>
   );
};
