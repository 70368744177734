import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import { store } from "./app/store";
import "./index.css";
import { FeatureFlagProvider } from "./hooks/FeatureFlags";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { createGlobalState } from "react-hooks-global-state";

// if (GA_MEASUREMENT_ID && GA_MEASUREMENT_ID.length !== 0) {
//    ReactGA.initialize(GA_MEASUREMENT_ID);
// }

const root = ReactDOM.createRoot(document.getElementById("root"));

let theme = createTheme({
   primary: {
      main: "333366",
   },
});

root.render(
   <FeatureFlagProvider>
      <ThemeProvider theme={theme}>
         <Provider store={store}>
            <Router>
               <App />
            </Router>
         </Provider>
      </ThemeProvider>
   </FeatureFlagProvider>
);
