import styled from "styled-components";
import { Theme } from "@informed-innovations/components";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 40px;
`;

const FeatureContainer = styled.div`
   color: ${Theme.colors.black};

   h2 {
      margin-top: 40px;
      font-size: 28px;
      margin-bottom: 25px;
   }

   p {
      text-align: justify;
      margin-left: 60px;
      margin-right: 60px;
   }

   ul {
      padding-left: 15px;
      color: #000;
   }

   a {
      text-decoration: underline;
   }

   @media screen and (max-width: 960px) {
      h2 {
         text-align: center;
         margin: 40px auto 25px auto;
      }

      p {
         margin: 0;
         margin-bottom: 10px;
         overflow-wrap: break-word;
      }

      a {
         overflow-wrap: break-word;
      }
   }
`;

const Col = styled.div`
   display: block;
`;

const ContentContainer = styled.div`
   margin: 0 auto;
   max-width: 1365px;
   margin-bottom: 60px;

   padding: 0 40px;

   @media screen and (max-width: 960px) {
      margin-bottom: 40px;
   }
`;

export const TermsConditions = props => {
   return (
      <Container>
         <ContentContainer>
            <Col>
               <FeatureContainer>
                  <h2>Contest Terms and Conditions</h2>
                  <p>
                     <b>Eligibility:</b> The Smart Locker Contest is open to all Conference Attendees who are 18 years
                     of age or older at the time of entry from
                     {" " +
                        props.tradeShow[0]["start-date"].substring(5, 7) +
                        "/" +
                        props.tradeShow[0]["start-date"].substring(8) +
                        "/" +
                        props.tradeShow[0]["start-date"].substring(0, 4)}{" "}
                     to
                     {" " +
                        props.tradeShow[0]["end-date"].substring(5, 7) +
                        "/" +
                        props.tradeShow[0]["end-date"].substring(8) +
                        "/" +
                        props.tradeShow[0]["end-date"].substring(0, 4)}
                     .
                  </p>
                  <p>
                     <b>How to Enter:</b> To enter, participants must submit a valid business email address at the
                     designated entry location or at the Locker Booth. Participants may enter the contest only once.
                  </p>
                  <p>
                     <b>Prize:</b> The prize for the Smart Locker Contest is one (1) item located within the designated
                     locker. The prize may vary and is non-transferable. No cash or other prize substitution shall be
                     permitted.
                  </p>
                  <p>
                     <b>Winner Selection:</b> Winners will be selected at random from all valid entries received. The
                     winner will be notified via email. If the winner does not pick up the prize within 48 hours of
                     notification, another winner may be selected.
                  </p>
                  <p>
                     <b>Locker Access:</b> To access the locker and claim the prize, the winner must present the QR code
                     <sup>&reg;</sup> received via email at the designated locker location. The winner is solely
                     responsible for any costs associated with transportation to and from the locker location.
                  </p>
                  <p>
                     <b>Liability:</b> The contest sponsors and their respective affiliates, employees, officers,
                     directors, and agents are not responsible for any injury, damage, or loss of any kind arising out
                     of participation in the Smart Locker Contest or the acceptance, use, or misuse of any prize.
                  </p>
                  <p>
                     <b>Disputes:</b> Any disputes arising out of the Smart Locker Contest or these Terms and Conditions
                     will not be considered.
                  </p>
                  <p>
                     <b>Customer Choice/Opt-in or Opt-out of customer marketing:</b> By entering the Smart Locker
                     Contest, participants agree to receive marketing emails from the contest sponsors. Participant
                     information will not be shared with any third parties except as necessary for prize fulfillment.
                  </p>
                  <p>
                     <b>General:</b> The contest sponsors reserve the right to cancel or modify the Smart Locker Contest
                     or these Terms and Conditions at any time without notice. The contest sponsors also reserve the
                     right to disqualify any participant who violates these Terms and Conditions.
                  </p>
               </FeatureContainer>
            </Col>
         </ContentContainer>
      </Container>
   );
};

export default TermsConditions;
