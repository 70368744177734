import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Outlet, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Landing from "../features/landing/Landing";
import Console from "../features/console/Console";
import Login from "../features/login/Login";
import { PageNotFound } from "../common/components/PageNotFound";
import { ScrollToTop } from "../common/components/ScrollToTop";
import ContestPage from "../features/contest-page/ContestPage";
import { TermsConditions } from "../features/contest-page/TermsConditions";
import PackageRecipients from "../features/customer/PackageRecipients";
import { LockerLookup } from "../features/locker-lookup/LockerLookup";
import { PackageShippers } from "../features/merchant/PackageShippers";
import Faq from "../features/faq/Faq";
import { Api } from "../services/Api";
import AuthContext from "../services/AuthContext";
import AuthService from "../services/AuthService";
import ContestClosed from "../features/contest-page/ContestClosed";
import { tradeShowCheck } from "../common/utils";
import CSVLockerLookup from "../features/locker-lookup/CSVLockerLookup";
import {
   BUSINESS_PAGE_URL,
   CUSTOMER_PAGE_URL,
   MERCHANT_PAGE_URL,
   LOCKER_LOCATION_PAGE_URL,
   FAQ_PAGE_URL,
   CONTEST_CLOSED_URL,
   NPF_TC_URL,
   NPF_URL,
} from "../config/pages";
import GuestReserve from "../features/locker-reservation/GuestReserve";
import GuestReserveCode from "../features/locker-reservation/GuestReserveCode";
import CurrentAndPastReservations from "../features/locker-reservation/CurrentAndPastReservations";
import { USPSGlobalFooter } from "../common/components/SMARTLOCKER-footer";
import { USPSNavBar } from "../common/components/nav-bar/USPSNavBar";
import { USPSGlobalHeader } from "../common/components/SMARTLOCKER-header";
import Business from "../features/business/Business";
import NPFContestPage from "../features/contest-page/NPFContestPage";
import NPFCartPage from "../features/contest-page/NPFCartPage";
import NPFThankYou from "../features/contest-page/NPFThankYou";
import ThankYou from "../features/contest-page/ThankYou";
import NPFProduct from "../features/contest-page/NPFProduct";

// const NavBarContentWrapper = styled.div``;
//
// const FlexWrapper = styled(NavBarContentWrapper)`
//    display: flex;
//    flex-direction: column;
//    min-height: calc(100vh - 101px);
//    justify-content: space-between;
// `;

const App = () => {
   const queryString = window.location.search;

   const authService = new AuthService();
   const [isLoggedIn, setIsLoggedIn] = useState(authService.isLoggedIn());
   const [tradeShow, setTradeShow] = useState();
   const [csvFlag, setCSVFlag] = useState();
   const [psFlag, setPSFlag] = useState();
   const [lockerList, setLockerList] = useState();
   const [allLockers, setAllLockers] = useState();

   const location = useLocation();
   const content = useRef(null);
   const navbar = useRef(null);

   const [contestLink, setContestLink] = useState(false);
   const [loading, setLoading] = useState(true);

   const getAllTradeShows = useCallback(async () => {
      return await api
         .getAllTradeShows()
         .then(response => tradeShowCheck(response.data, "show-id", Number(localStorage.getItem("show_id"))));
   }, []);

   const getFeatureFlag = useCallback(async featureFlag => {
      return await api.getFeatureFlag(featureFlag);
   }, []);

   const getCurrentLockerList = useCallback(async () => {
      return await api.getCurrentLockerList();
   }, []);

   const getAllLockers = useCallback(async () => {
      const response = await api.postGetCSVLocationsByCityState("Lebanon", "Kansas", 10000);
      return response;
   });

   useEffect(() => {
      (async () => {
         setTradeShow(await getAllTradeShows());
         // Look for whether new CSV search is being used or not
         const csvResponse = await getFeatureFlag("csv-search");
         setCSVFlag(csvResponse.data.response);
         const psResponse = await getFeatureFlag("hide-ps");
         setPSFlag(psResponse.data.response);
         setLockerList(await getCurrentLockerList());
         setLoading(false);
         setAllLockers(await getAllLockers());
      })();
   }, [getAllTradeShows, getFeatureFlag, getCurrentLockerList]);

   const handleLogout = () => {
      localStorage.setItem("isLoggedIn", "false");
      setIsLoggedIn(false);
   };

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const resizeContent = useCallback(() => {
      if (!content.current || !navbar.current) return;
      content.current.style.marginTop = `${navbar.current.getBoundingClientRect().height}px`;
   });

   useEffect(() => {
      resizeContent();
   }, [resizeContent]);

   useEffect(() => {
      window.addEventListener("resize", resizeContent);
      return () => window.removeEventListener("resize", resizeContent);
   }, [resizeContent]);

   useEffect(() => {
      if (location.pathname !== "/authentication/callback") {
         ReactGA.send({ hitType: "pageview", page: location.pathname });
      }
   }, [location]);

   useEffect(() => {
      if (queryString) {
         const urlParams = new URLSearchParams(queryString);

         localStorage.setItem("show_id", urlParams.get("show_id"));
         setContestLink(true);
      }

      if (localStorage.getItem("show_id") && !contestLink) setContestLink(true);
   }, [contestLink, queryString]);

   const reload = () => window.location.reload();

   const api = new Api();
   api.setup();

   const [ignore, setIgnore] = useState(false);

   const logAnalytics = async () => {
      return await api.postLogAnalytics();
   };

   useEffect(() => {
      if (!ignore) {
         logAnalytics();
         setIgnore(true);
      }
   }, [ignore]);

   const ProtectedRoute = ({ isAllowed, redirectPath = "/login", children }) => {
      if (!isAllowed) {
         return <Navigate to={redirectPath} replace />;
      }

      return children ? children : <Outlet />;
   };

   if (!loading) {
      return (
         <React.Fragment>
            <ScrollToTop>
               <AuthContext.Provider value={{ isLoggedIn, handleLogout }}>
                  <Routes>
                     <Route element={<Outlet />}>
                        <Route exact path="/robots.txt" onEnter={reload} />
                     </Route>
                     <Route
                        element={
                           <>
                              <USPSGlobalHeader tradeShow={tradeShow} psFlag={psFlag} />
                              <USPSNavBar ref={navbar} tradeShow={tradeShow} psFlag={psFlag} />
                              <Outlet />
                              <USPSGlobalFooter />
                           </>
                        }
                     >
                        <Route path="/" element={<Landing />} />
                        {psFlag === "true" ? <></> : <Route path={MERCHANT_PAGE_URL} element={<PackageShippers />} />}
                        <Route
                           path="/login"
                           element={<Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />}
                        />
                        <Route path={CUSTOMER_PAGE_URL} element={<PackageRecipients />} />
                        <Route path={BUSINESS_PAGE_URL} element={<Business />} />
                        <Route
                           path={LOCKER_LOCATION_PAGE_URL}
                           element={csvFlag === "true" ? <CSVLockerLookup lockerList={lockerList} /> : <LockerLookup />}
                        />
                        <Route
                           path="/console"
                           element={
                              <ProtectedRoute isAllowed={isLoggedIn}>
                                 <Console />
                              </ProtectedRoute>
                           }
                        />
                        {tradeShow && tradeShow.length !== 0 && (
                           <>
                              <Route path="/contest-page/:uuid?" element={<ContestPage tradeShow={tradeShow} />} />
                              <Route path={NPF_URL}>
                                 <Route path="" element={<NPFContestPage tradeShow={tradeShow} />} />
                                 <Route path="cart" element={<NPFCartPage tradeShow={tradeShow} />} />
                                 <Route path="thank-you" element={<NPFThankYou tradeShow={tradeShow} />} />
                              </Route>
                           </>
                        )}
                        <Route path={NPF_TC_URL} element={<TermsConditions tradeShow={tradeShow} />} />
                        <Route path={FAQ_PAGE_URL} element={<Faq />} />
                        <Route path={CONTEST_CLOSED_URL} element={<ContestClosed />} />
                        {process.env.REACT_APP_GUEST_RESERVATION === "true" && (
                           <Route path="guest-reservation">
                              <Route path="" element={<PageNotFound />} />
                              <Route path="email" element={<GuestReserve />} />
                              <Route path="code" element={<GuestReserveCode />} />
                              <Route path="success" element={<CurrentAndPastReservations />} />
                              <Route path="*" element={<PageNotFound />} />
                           </Route>
                        )}
                        <Route path="*" element={<PageNotFound />} />
                     </Route>
                  </Routes>
               </AuthContext.Provider>
            </ScrollToTop>
         </React.Fragment>
      );
   }
};

export default App;
