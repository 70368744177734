import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login({ setIsLoggedIn, isLoggedIn }) {
   const navigate = useNavigate();

   const [user, setUser] = useState("");
   const [password, setPassword] = useState("");
   const [errorMessage, setErrorMessage] = useState("");

   const handleUserChange = event => setUser(event.target.value);
   const handlePasswordChange = event => setPassword(event.target.value);

   const handleSubmit = event => {
      event.preventDefault();

      // check if the username and password are correct
      if (
         user === process.env.REACT_APP_FF_CONSOLE_USERNAME &&
         password === process.env.REACT_APP_FF_CONSOLE_PASSWORD
      ) {
         // set the logged in user in the AuthContext
         // setLoggedInUser(user);

         // set the isLoggedIn state to true in the App component
         setIsLoggedIn(true);

         // save the isLoggedIn state in localStorage
         localStorage.setItem("isLoggedIn", "true");

         // redirect to the console page
         navigate("/console");
      } else {
         setErrorMessage("Invalid user or password.");
      }
   };

   useEffect(() => {
      if (isLoggedIn) navigate("/console");
   }, [isLoggedIn, navigate]);

   return (
      <form onSubmit={handleSubmit}>
         <div>
            <label htmlFor="user">Username:</label>
            <input type="text" id="user" value={user} onChange={handleUserChange} required />
         </div>
         <div>
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" value={password} onChange={handlePasswordChange} required />
         </div>
         {errorMessage && <div>{errorMessage}</div>}
         <button type="submit">Login</button>
      </form>
   );
}

export default Login;
