import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Api } from "../../services/Api";
import { Loading } from "../../common/components/Loading";
import { Modal } from "../../common/components/Modal";
import mobileHero from "../../static/img/locker-open-with-boxes-mobile-min.jpg";
import LogExternalLink from "../../common/components/LogExternalLink";
import LogInternalLink from "../../common/components/LogInternalLink";
import RepeatEntry from "./RepeatEntry";
import styled from "styled-components";
import ThankYou from "./ThankYou";
import {
   Col,
   Container,
   ContentContainer,
   FeatureContainer,
   HeroContainer,
   HeroContentContainer,
   HeroImage,
   HeroInnerContainer,
   Row,
} from "../../common/components/WebLayoutComponents";
import NPFContestPage from "./NPFContestPage";
import NPFCartPage from "./NPFCartPage";
import { CONTEST_CLOSED_URL, NPF_TC_URL } from "../../config/pages";

const FormContainer = styled.div`
   display: flex;
   max-width: 43%;

   @media screen and (max-width: 960px) {
      flex-direction: column;
      max-width: 100%;
   }
`;

const FormInputContainer = styled.div`
   display: flex;

   @media screen and (max-width: 960px) {
      flex-direction: column;
      gap: 20px;
   }
`;

const TextBoxHolder = styled.div`
   display: flex;
   flex-direction: column;
   width: 50%;
   min-width: fit-content;

   @media screen and (max-width: 960px) {
      padding: 0;
      width: 100%;
      min-width: 100%;

      .form-text-input {
         width: 100%;
      }
   }

   p {
      margin: 0;
   }

   .form-text-input {
      padding: 12px 10px;
      min-width: 100%;
      border-radius: 5px;
      border-width: 1px;
   }

   .form-input-invalid {
      color: red;
   }

   .form-input-valid {
      display: none;
      height: 18px;
   }

   .submit-button {
      padding: 12px 10px;
      border: none;
      border-radius: 3px;
      min-width: 300px;
      max-width: 300px;
      margin: auto;
      background: white;
      color: #333366;
      font-weight: bold;
   }

   .submit-button:disabled {
      background: #ededed;
      color: #d3d3d3;
   }

   .submit-button:hover {
      background: #d3d3d3;
      color: #343366;
   }

   .submit-button:hover:disabled {
      background: #ededed;
      color: #d3d3d3;
   }
`;

const FormTextContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin: 20px auto;
   max-width: 600px;
   text-align: justify;

   @media screen and (max-width: 960px) {
      max-width: 350px;
      text-align: center;
   }

   .contest-checkbox {
      width: 26px;
      height: 26px;
      margin-right: 10px;
   }

   a {
      color: lightgrey;
      text-decoration: underline;
   }

   a:hover {
      text-decoration: none;
   }

   NavLink {
      border: none;
   }

   p {
      margin: 0 auto;
   }

   p:not(:last-child) {
      margin-bottom: 20px;
   }
`;

const CheckboxContainer = styled.div`
   display: flex;
`;

export const ContestPage = props => {
   const [email, setEmail] = useState("");
   const [emailErrorState, setEmailErrorState] = useState(false);
   const [confirmedEmail, setConfirmedEmail] = useState("");
   const [confirmedEmailErrorState, setConfirmedEmailErrorState] = useState(false);
   const [termsCheckbox, setTermsCheckbox] = useState(false);
   const [submitDisabled, setSubmitDisabled] = useState(false);
   const [submitError, setSubmitError] = useState(false);
   const [repeatEntry, setRepeatEntry] = useState(false);
   const [formValid, setFormValid] = useState(false);
   const [repeatFormValid, setRepeatFormValid] = useState(false);
   const [uuid, setUUID] = useState();

   const [showId, setShowId] = useState(localStorage.getItem("show_id"));
   const pathNameUUID = window.location.pathname.substring(14);

   useEffect(() => {
      setShowId(localStorage.getItem("show_id"));
   }, [showId]);

   const api = new Api();
   api.setup();

   const handleEmailChange = e => {
      setEmail(e.target.value);
      if (!emailErrorState) {
         setEmailErrorState(true);
      }
   };

   const handleConfirmedEmailChange = e => {
      setConfirmedEmail(e.target.value);
      if (!confirmedEmailErrorState) {
         setConfirmedEmailErrorState(true);
      }
   };

   const resetState = () => {
      setEmail("");
      setEmailErrorState(false);
      setConfirmedEmail("");
      setConfirmedEmailErrorState(false);
      setSubmitDisabled(false);
      document.getElementById("contest-page-form").reset();
   };

   const emailPost = async (email, showId) => {
      return await api.postContestPageEmail(email, showId);
   };

   const handleSubmit = e => {
      e.preventDefault();
      setSubmitDisabled(true);

      emailPost(email, props.tradeShow[0]["show-id"])
         .then(resp => {
            if (resp.data.status === 5) {
               setRepeatFormValid(true);
               setUUID(resp.data.uuid);
               resetState();
            } else if (resp.data.status === 0) {
               setFormValid(true);
               setUUID(resp.data.uuid);
               resetState();
            } else {
               setSubmitError(true);
               setSubmitDisabled(false);
            }
         })
         .catch(error => {
            console.log(error);
            setSubmitError(true);
            setSubmitDisabled(false);
         });
   };

   function returnMainContestPage() {
      if (props.tradeShow[0]["contest-flag"]) {
         return (
            <Container>
               <Modal modalActive={submitError} closeFunction={() => setSubmitError(false)}>
                  <h1>Hmm...</h1>
                  <p>Looks like something went wrong, please try again.</p>
                  <button onClick={() => setSubmitError(false)}>Close</button>
               </Modal>
               <Modal modalActive={repeatEntry} closeFunction={() => setRepeatEntry(false)}>
                  <h1>Already Submitted</h1>
                  <p>This contest only allows one entry per email.</p>
                  <button onClick={() => setRepeatEntry(false)}>Close</button>
               </Modal>
               <HeroContainer className="landing">
                  <HeroInnerContainer>
                     <HeroImage
                        src={mobileHero}
                        alt="USPS Smart Locker with dozens of package compartments; a screen, scanner, and keypad; and an open compartment with Priotiry Mail Packages in front of it."
                     />
                     <HeroContentContainer>
                        <h1>{props.tradeShow[0]["show-name"]} Contest Entry Form</h1>
                        <FormContainer>
                           <form id="contest-page-form" onSubmit={handleSubmit}>
                              <FormInputContainer>
                                 <TextBoxHolder style={{ marginRight: "20px" }}>
                                    <p>*Enter Business Email Address</p>
                                    <input
                                       className="form-text-input"
                                       type="email"
                                       placeholder="Enter Business Email Address"
                                       onChange={handleEmailChange}
                                    />
                                    <p
                                       className={
                                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                             email
                                          ) || !emailErrorState
                                             ? "form-input-valid"
                                             : "form-input-invalid"
                                       }
                                    >
                                       Invalid Email Address
                                    </p>
                                 </TextBoxHolder>
                                 <TextBoxHolder>
                                    <p>*Confirm Business Email Address</p>
                                    <input
                                       className="form-text-input"
                                       type="email"
                                       placeholder="Confirm Business Email Address"
                                       onChange={handleConfirmedEmailChange}
                                    />
                                    <p
                                       className={
                                          confirmedEmail === email || !confirmedEmailErrorState
                                             ? "form-input-valid"
                                             : "form-input-invalid"
                                       }
                                    >
                                       Email does not match or is invalid
                                    </p>
                                 </TextBoxHolder>
                              </FormInputContainer>
                              <Col>
                                 <FormTextContainer>
                                    <p>
                                       <strong>Privacy Notice: </strong>
                                       For information regarding our privacy policies, visit{" "}
                                       <LogExternalLink href="https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm">
                                          usps.com/privacypolicy
                                       </LogExternalLink>
                                       .
                                    </p>
                                    <CheckboxContainer>
                                       <input
                                          type="checkbox"
                                          className="contest-checkbox"
                                          onChange={() => setTermsCheckbox(!termsCheckbox)}
                                       />
                                       <p>
                                          I agree with the{" "}
                                          <LogInternalLink alternate to={NPF_TC_URL} target="_blank">
                                             terms and conditions
                                          </LogInternalLink>{" "}
                                          of the {props.tradeShow[0]["show-name"]} Smart Locker contest and to receiving
                                          an email with a QR code to see if I am a winner.
                                       </p>
                                    </CheckboxContainer>
                                 </FormTextContainer>
                                 <TextBoxHolder>
                                    <input
                                       className="submit-button"
                                       type="submit"
                                       value={submitDisabled ? "Please Wait..." : "Submit"}
                                       disabled={
                                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                             email
                                          ) ||
                                          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                             confirmedEmail
                                          ) ||
                                          !termsCheckbox ||
                                          submitDisabled ||
                                          !(email === confirmedEmail)
                                       }
                                    />
                                 </TextBoxHolder>
                              </Col>
                           </form>
                        </FormContainer>
                     </HeroContentContainer>
                  </HeroInnerContainer>
               </HeroContainer>
               <ContentContainer className="medium-pb">
                  <Row>
                     <Col>
                        <FeatureContainer>
                           <h2>How it Works</h2>
                           <p>
                              The U.S. Postal Service (USPS) is unveiling its Smart Lockers to cities across the U.S. to
                              provide customers with a safe, convenient, and secure alternative option for receiving
                              packages.
                           </p>

                           <p>
                              USPS<sup>&reg;</sup> Smart Lockers allow customers to securely receive valuable packages
                              without needing to be home, using a secure QR code for access at any time.
                           </p>
                        </FeatureContainer>
                     </Col>
                     <Col>
                        <FeatureContainer>
                           <h2>Benefits</h2>
                           <ul>
                              <li>
                                 <strong>Convenience</strong>: USPS Smart Lockers enable you to receive packages at any
                                 time, without the hassle of needing to be home for delivery. Easily retrieve packages
                                 from the locker at your own convenience, 24/7.
                              </li>
                              <li>
                                 <strong>Security</strong>: USPS Smart Lockers provide secure, theft-proof delivery of
                                 packages via a QR code that only the intended package recipient can access.
                              </li>
                              <li>
                                 <strong>Reliability</strong>: Enjoy peace of mind with convenient and risk-free
                                 delivery of your USPS packages, eliminating the need for rescheduling and avoiding any
                                 missed delivery risks.
                              </li>
                              <li>
                                 <strong>Stay Informed</strong>: Track your packages by signing up for{" "}
                                 <LogExternalLink href="https://informeddelivery.usps.com">
                                    Informed Delivery<sup>&reg;</sup>
                                 </LogExternalLink>{" "}
                                 feature!
                              </li>
                           </ul>
                        </FeatureContainer>
                     </Col>
                  </Row>
               </ContentContainer>
            </Container>
         );
      } else return <Navigate replace to={"/"} />;
   }

   if (pathNameUUID) {
      return <RepeatEntry uuid={pathNameUUID} />;
   } else if (formValid && uuid) {
      return <ThankYou uuid={uuid} />;
   } else if (repeatFormValid && uuid) {
      return <RepeatEntry uuid={uuid} />;
   } else if (formValid || repeatFormValid) {
      return <Loading />;
   } else {
      returnMainContestPage();
   }
};

export default ContestPage;
