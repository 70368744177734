import styled from "styled-components";
import { Theme } from "@informed-innovations/components";
import Checkmark from "../../static/img/Checkmark.PNG";
import LogInternalLink from "../../common/components/LogInternalLink";
import { isAndroid, isIOS, isTablet } from "react-device-detect";
import { useEffect, useState, useCallback } from "react";
import AppleWalletImage from "../../static/img/apple-wallet.png";
import { loadScript } from "../../common/utils";
import { Api } from "../../services/Api";
import { Loading } from "../../common/components/Loading";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   gap: 40px;
`;

const FeatureContainer = styled.div`
   color: ${Theme.colors.black};
   text-align: center;
   display: flex;
   flex-direction: column;

   a {
      border-radius: 5px;
      max-width: 200px;
      margin: 0 auto;
   }

   b {
      margin: 50px auto 5px;
   }

   p {
      max-width: 800px;
   }

   .checkmark {
      height: 40px;
      width: 40px;
      margin: 40px auto 20px auto;
   }

   .qr-code {
      height: 200px;
      width: 200px;
      margin: 20px auto;
   }

   h2 {
      font-size: 28px;
      margin-bottom: 25px;
      color: #218748;
   }

   .no-checkmark-header {
      color: #336;
   }

   ul {
      padding-left: 15px;
      color: #000;
   }

   @media screen and (max-width: 960px) {
      h2 {
         text-align: center;
      }
      p:first-of-type {
         margin-bottom: 25px;
      }
   }
`;

const Col = styled.div`
   display: block;
`;

const ContentContainer = styled.div`
   margin: 0 auto;
   max-width: 1365px;
   margin-bottom: 60px;

   padding: 0 40px;

   @media screen and (max-width: 960px) {
      margin-bottom: 40px;
   }
`;

export const RepeatEntry = props => {
   const api = new Api();
   api.setup();

   const uuid = props.uuid;
   const pathNameUUID = window.location.pathname.substring(14);

   const [jwtQuery, setJwtQuery] = useState();
   const [ignore, setIgnore] = useState(false);
   const [idPassed, setIDPassed] = useState(false);

   const getJwtQuery = async uuid => {
      return await api.getJwtQuery(uuid);
   };

   const handleGoogleApiLoad = useCallback(() => {
      window.gapi.savetoandroidpay.render("androidPayContainer", {
         jwt: jwtQuery.jwt,
      });
   }, [jwtQuery]);

   useEffect(() => {
      if (isAndroid && jwtQuery && jwtQuery.jwt) {
         loadScript("https://apis.google.com/js/platform.js", handleGoogleApiLoad);
      }
   }, [handleGoogleApiLoad, jwtQuery]);

   useEffect(() => {
      if (!ignore) {
         getJwtQuery(uuid).then(resp => {
            if (!resp.data) {
               window.location.href = "/contest-page";
            } else {
               setJwtQuery(resp.data);
               setIDPassed(true);
            }
         });
         setIgnore(true);
      }
   }, [ignore]);

   if (idPassed) {
      return (
         <Container>
            <ContentContainer>
               <Col>
                  <FeatureContainer>
                     {pathNameUUID ? (
                        <div>
                           <h2 className="no-checkmark-header">
                              Add QR code to Your Wallet!
                           </h2>
                           <p>
                              If you're on a mobile device, simply press the button below the QR code to add it to your
                              wallet!
                           </p>
                        </div>
                     ) : (
                        <div>
                           <img src={Checkmark} alt="Checkmark" className="checkmark" />
                           <h2>
                              Already Entered! Here is your QR code!
                           </h2>
                           <p>
                              You should have also received your QR code in your email that you will be able to use with
                              our locker! Please be sure to keep this QR code somewhere safe such as your mobile wallet
                              or take a screenshot of this page as you will need your QR code to access your locker.
                           </p>
                        </div>
                     )}
                     <img
                        className="qr-code"
                        src={process.env.REACT_APP_API_URL + "/media/" + uuid + "/qr"}
                        alt="QR code"
                     />
                     <div>
                        {isIOS && !isTablet ? (
                           <div>
                              <a href={process.env.REACT_APP_API_URL + "/wallet/" + uuid}>
                                 <img src={AppleWalletImage} alt="Apple Pay" className="wallet-image" />
                              </a>
                           </div>
                        ) : isAndroid ? (
                           <div id="androidPayContainer" />
                        ) : null}
                     </div>
                     <b>
                        Click here to return to the Home Page for USPS<sup>&reg;</sup> Smart Lockers
                     </b>
                     <LogInternalLink primary outline="white" to="/">
                        Home Page
                     </LogInternalLink>
                  </FeatureContainer>
               </Col>
            </ContentContainer>
         </Container>
      );
   } else {
      return <Loading />;
   }
};

export default RepeatEntry;
