import { createContext, useEffect, useState } from "react";
import { Api } from "../services/Api";

export const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
   const api = new Api();
   api.setup();

   const [flags, setFlags] = useState({});
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      (async () => {
         await api
            .getFeatureFlags()
            .then(response => response?.data?.responseList)
            .then(data => {
               setFlags(data);
               setLoading(false);
            });
      })();
   }, []);

   if (!loading) {
      return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>;
   }
};
